<div class="input-group">
    <div class="form-floating" [formGroup]="formGroup">
        <input
            [id]="name"
            [type]="type"
            class="form-control"
            [class.is-invalid]="isFormControlInvalid"
            [class.is-valid]="isFormControlValid"
            [placeholder]="placeholder"
            [formControlName]="name"
        >
        <label [for]="name">
            <span [innerHTML]="label"></span>
        </label>
    </div>
    <button
        (click)="toggleVisibility()"
        class="btn btn-form"
        [class.btn-outline-form]="!isFormControlInvalid && !isFormControlValid"
        [class.btn-outline-danger]="isFormControlInvalid"
        [class.btn-outline-success]="isFormControlValid"
        type="button"
        id="button-addon1"
        [innerHTML]="visibilityIcon">
    </button>
</div>
