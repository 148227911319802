import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";

import { Metadata } from "../../services/meta/meta.interface";
import { StorageEnum } from "../../enums/storage.enum";
import { MetaService } from "../../services/meta/meta.service";
import { HttpService } from "../../services/http/http.service";
import { StorageService } from "../../services/storage/storage.service";
import { ApplicationService } from "../application/application.service";
import { RequestEndpointEnum } from "../../enums/request-endpoint.enum";
import { ApplicationRedirectData } from "../application/application.interface";

@Component({
    selector: "app-redirect",
    templateUrl: "./redirect.component.html",
    styleUrls: ["./redirect.component.scss"],
})
export class RedirectComponent implements OnInit, OnDestroy {
    private _redirectTime!: number;
    private _canDeactivateInstance: boolean = true;

    public constructor(
        private readonly router: Router,
        private readonly metaService: MetaService,
        private readonly httpService: HttpService,
        private readonly storageService: StorageService,
        private readonly applicationService: ApplicationService,
    ) {
        this.checkRedirectDataExistence();
        this.storageService.removeItem(StorageEnum.APPLICATION_SEQUENCE);
        this.storageService.setItem(StorageEnum.APPLICATION_PROGRESS, StorageEnum.APPLICATION_PROGRESS_NOT_STARTED_VALUE);
    }

    public ngOnInit(): void {
        setTimeout(() => {
            this.redirectToURL();
        }, this.metadata.application.redirect.intervalInSeconds * 1000);
    }

    public ngOnDestroy(): void {
        this.applicationService.resetRequestedApplication();
    }

    private checkRedirectDataExistence(): void {
        if (!this.redirectData) {
            this.router.navigate(["/"]).then();
        }
    }

    public get redirectTime(): number {
        return this._redirectTime;
    }

    public set redirectTime(redirectTime: number) {
        this._redirectTime = redirectTime;
    }

    public get redirectData(): ApplicationRedirectData {
        return this.applicationService.redirectData;
    }

    public redirectToURL(): void {
        this.httpService.post("/will-redirect", {
            id: this.redirectData.applicationId,
        }).subscribe({
            next: () => {
                window.open(this.redirectData.redirectUrl, "_self");
            },
        });
    }

    @HostListener("window:beforeunload")
    private canDeactivate(): Observable<boolean> | boolean {
        return this.canDeactivateInstance;
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public get instanceName(): RequestEndpointEnum {
        return RequestEndpointEnum.APPLICATION_RESUME;
    }

    public get canDeactivateInstance(): boolean {
        return this._canDeactivateInstance;
    }

    public set canDeactivateInstance(canDeactivate: boolean) {
        this._canDeactivateInstance = canDeactivate;
    }
}
