import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";

import { ApplicationService } from "./application.service";
import { ApplicationMetadata } from "./application.interface";

export const applicationResolver: ResolveFn<ApplicationMetadata> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const service: ApplicationService = inject(ApplicationService);
    return service.getMetadata();
};

// export const applicationUtmSeqResolver: ResolveFn<ApplicationMetadata> = (
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot,
// ) => {
//     const service: ApplicationService = inject(ApplicationService);
//     return service.getUtmSeq();
// };
