import { Component } from "@angular/core";
import { BaseFormInput } from "../base-form-input";

@Component({
    selector: "app-input-radio-group",
    templateUrl: "./radio-group.component.html",
    styleUrls: ["./radio-group.component.scss"],
})
export class RadioGroupComponent extends BaseFormInput {
    public constructor() {
        super();
    }

    public ngAfterViewInit(): void {
        // TODO: Find better solution or is it good enough?
        // Without setTimeout (0ms) it's not working.
        setTimeout(() => {
            this.formGroup.get(this.name)?.patchValue(null);
        });
    }
}
