import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { StorageEnum } from "../enums/storage.enum";
import { ModalService } from "../services/modal/modal.service";
import { StorageService } from "../services/storage/storage.service";
import { ModalWarningComponent } from "../components/modal/warning/modal-warning.component";

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard {
    public constructor(
        private readonly modalService: ModalService,
        private readonly storageService: StorageService,
    ) {
    }

    public canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        const isPendingChangesIgnored: boolean = this.storageService.getItem(StorageEnum.IGNORE_PENDING_CHANGES) == "true";

        if (isPendingChangesIgnored) {
            return true;
        }

        return component.canDeactivate() ? true : this.openConfirmationModal();
    }

    public openConfirmationModal(): Observable<boolean> {
        const result: Subject<boolean> = new Subject<boolean>();

        this.modalService.open(ModalWarningComponent).result.then(({ redirect }): void => {
            result.next(redirect);
        }, () => {
        });

        return result.asObservable();
    }
}
