export class DateUtil {
    public static getCurrentUnixTimestamp(): number {
        return Math.floor(Date.now() / 1000);
    }

    public static convertDatetimeToUnixTimestamp(date: Date): number {
        return Math.floor(date.getTime() / 1000);
    }

    public static convertDatetimeToDateISO(date: Date): string {
        return date.toISOString().split("T")[0];
    }

    public static setDaysToDate(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }
}
