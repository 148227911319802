import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { UnsubscribeService } from "./unsubscribe.service";
import { UnsubscribeMetadata } from "./unsubscribe.interface";

export const unsubscribeResolver: ResolveFn<UnsubscribeMetadata> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const service: UnsubscribeService = inject(UnsubscribeService);
    return service.getMetadata();
};
