import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxMaskDirective } from "ngx-mask";
import { NgbDropdownModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterLink, RouterLinkActive } from "@angular/router";

import { TosComponent } from "./tos/tos.component";
import { TextComponent } from "./form/text/text.component";
import { BlankComponent } from "./blank/blank.component";
import { RadioComponent } from "./form/radio/radio.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { LayoutComponent } from "./layout/layout.component";
import { FooterComponent } from "./footer/footer.component";
import { SelectComponent } from "./form/select/select.component";
import { NumberComponent } from "./form/number/number.component";
import { LoaderComponent } from "./loader/loader.component";
import { UnnaxComponent } from "./iframe/unnax/unnax.component";
import { IframeComponent } from "./iframe/iframe.component";
import { PasswordComponent } from "./form/password/password.component";
import { DatetimeComponent } from "./form/datetime/datetime.component";
import { CheckboxComponent } from "./form/checkbox/checkbox.component";
import { CountDownComponent } from "./count-down/count-down.component";
import { FormInputComponent } from "./form/input/input.component";
import { FormErrorComponent } from "./form/error/error.component";
import { FormHelperComponent } from "./form/helper/helper.component";
import { RadioGroupComponent } from "./form/radio-group/radio-group.component";
import { PhoneNumberComponent } from "./form/phone-number/phone-number.component";
import { FormTextareaComponent } from "./form/textarea/textarea.component";
import { CheckboxComboComponent } from "./form/checkbox-combo/checkbox-combo.component";
import { SelectDropdownComponent } from './form/select-dropdown/select-dropdown.component';

@NgModule({
    imports: [
        CommonModule,
        RouterLinkActive,
        RouterLink,
        ReactiveFormsModule,
        NgxMaskDirective,
        NgbTooltipModule,
        NgbDropdownModule,
    ],
    declarations: [
        NavbarComponent,
        LayoutComponent,
        FooterComponent,
        BlankComponent,
        FormInputComponent,
        FormTextareaComponent,
        FormErrorComponent,
        FormHelperComponent,
        CheckboxComponent,
        PhoneNumberComponent,
        DatetimeComponent,
        RadioComponent,
        RadioGroupComponent,
        SelectComponent,
        NumberComponent,
        TextComponent,
        LoaderComponent,
        TosComponent,
        IframeComponent,
        UnnaxComponent,
        CountDownComponent,
        PasswordComponent,
        CheckboxComboComponent,
        SelectDropdownComponent,
    ],
    exports: [
        NavbarComponent,
        LayoutComponent,
        FooterComponent,
        BlankComponent,
        FormInputComponent,
        FormTextareaComponent,
        FormErrorComponent,
        FormHelperComponent,
        CheckboxComponent,
        PhoneNumberComponent,
        TextComponent,
        LoaderComponent,
        TosComponent,
        IframeComponent,
        CountDownComponent,
        PasswordComponent,
        RadioGroupComponent,
    ],
})
export class ComponentsModule {
}
