<div class="form-floating" [formGroup]="formGroup">
    <input
        [id]="name"
        [type]="type"
        class="form-control"
        [class.is-invalid]="isFormControlInvalid"
        [class.is-valid]="isFormControlValid"
        [placeholder]="placeholder"
        [formControlName]="name"
    >
    @if (isLoading) {
        <div class="form-control-spinner">
            <div class="spinner-border spinner-border-sm"></div>
        </div>
    }
    <label [for]="name">
        <span [innerHTML]="label"></span>
    </label>
</div>
