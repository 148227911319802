import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Iframe } from "../iframe";

@Component({
    selector: "app-unnax",
    templateUrl: "./unnax.component.html",
    styleUrls: ["./unnax.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnnaxComponent extends Iframe {
    public constructor(
        protected override readonly sanitizer: DomSanitizer,
    ) {
        super(sanitizer);
    }
}
