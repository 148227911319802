import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { inject } from "@angular/core";

import { ShortUrlService } from "./short-url.service";

export const shortUrlResolver: ResolveFn<{ url: string; }> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const service: ShortUrlService = inject(ShortUrlService);
    return service.getUrlByPath(String(route.paramMap.get("path")));
};
