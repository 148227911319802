<app-blank
    image="header/header-background-8.jpg"
    [title]="metadata.about.main.heading"
    [isLoading]="isInstanceLoading"
    [instanceName]="instanceName">
    @if (!isInstanceLoading) {
        <section class="py-5">
            <div class="container">
                <p [innerHTML]="metadata.about.main.content"></p>

                <div class="d-flex justify-content-center">
                    <div class="text-center">
                        <button
                            buttonLink="/solicita"
                            [buttonLinkDelay]="650"
                            class="btn btn-secondary px-5 mt-4">
                            {{ metadata.about.main.ctaButton }}
                        </button>

                        <p class="mt-2">
                            {{ metadata.about.main.ctaAfter }}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    }
</app-blank>
