import { NgbActiveModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { Component, Input } from "@angular/core";

import { Metadata } from "../../../services/meta/meta.interface";
import { MetaService } from "../../../services/meta/meta.service";
import { StorageEnum } from "../../../enums/storage.enum";
import { ApplicationService } from "../../../instances/application/application.service";
import { Router } from "@angular/router";
import { StorageService } from "../../../services/storage/storage.service";

@Component({
    selector: "app-modal-warning",
    standalone: true,
    templateUrl: "./modal-returned.component.html",
    styleUrls: ["./modal-returned.component.scss"],
})
export class ModalReturnedComponent {
    @Input() public data!: string;

    public constructor(
        private readonly router: Router,
        private readonly metaService: MetaService,
        private readonly activeModal: NgbActiveModal,
        private readonly storageService: StorageService,
        private readonly applicationService: ApplicationService,
    ) {
    }

    public continue(): void {
        this.activeModal.close({ redirect: false });
    }

    public async submit(): Promise<void> {
        if (this.isApplicationReturned) {
            this.activeModal.close({ redirect: false });
        } else {
            this.applicationService.fields = null;
            this.applicationService.applicationSequence = null;
            this.storageService.setItem(StorageEnum.IGNORE_PENDING_CHANGES, true);
            this.applicationService.applicationProgress = StorageEnum.APPLICATION_PROGRESS_NOT_STARTED_VALUE;
            this.applicationService.isApplicationReturned = null;
            await this.router.navigateByUrl("/");
            this.activeModal.close({ redirect: true });
        }
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public get isApplicationReturned(): boolean | null {
        return this.applicationService.isApplicationReturned;
    }
}
