export enum RequestEndpointEnum {
    POLICY_TERMS = "terms_and_conditions",
    POLICY_PRIVACY = "privacy_policy",
    POLICY_COOKIES = "cookie_policy",
    APPLICATION_RESUME = "application_resume",
    FAQ = "faq",
    HOW = "how_it_works",
    ABOUT = "about_us",
    HOME = "home",
    GENERAL = "b_home",
    CONTACT = "contact",
    UNSUBSCRIBE = "unsubscribe",
}
