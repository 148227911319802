import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BaseFormInput } from "../base-form-input";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormValidator } from "../../../validators/form-validator.validator";

@Component({
    selector: "app-input-datetime",
    templateUrl: "./datetime.component.html",
    styleUrls: ["./datetime.component.scss"],
})
export class DatetimeComponent extends BaseFormInput implements OnInit {
    public formDatetimeGroup!: FormGroup;
    @Output() public hasValidator: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constructor(
        private readonly formBuilder: FormBuilder,
    ) {
        super();
    }

    public ngOnInit(): void {
        const year: number = new Date().getFullYear();

        this.formDatetimeGroup = this.formBuilder.group({
            day: [null, [
                Validators.required,
                Validators.min(1),
                Validators.max(31),
                FormValidator.isTypeNumber(),
            ]],
            month: [null, [
                Validators.required,
                Validators.min(1),
                Validators.max(12),
                FormValidator.isTypeNumber(),
            ]],
            year: [null, [
                Validators.required,
                Validators.min(year - this.maxYear),
                Validators.max(year - this.minYear),
                FormValidator.isTypeNumber(),
            ]],
        }, {
            validators: [
                FormValidator.dayExists(),
            ],
        });

        this.formDatetimeGroup.valueChanges.subscribe((value): void => {
            const dd = this.formDatetimeGroup.value.day;
            const mm = this.formDatetimeGroup.value.month;
            const yy = this.formDatetimeGroup.value.year;
            const date: Date = new Date();

            date.setDate(dd);
            date.setMonth(mm - 1);
            date.setFullYear(yy);

            if (dd && mm && yy) {
                this.formGroup.patchValue({
                    [this.name]: this.formatDatetimeToDate(date),
                });
            }

            this.formGroup.get(this.name)?.markAsDirty();

            if (this.formDatetimeGroup.invalid) {
                this.formGroup.get(this.name)?.setErrors({ invalid: true });
            }

            if (this.isFormDatetimeInvalid()) {
                this.hasValidator.emit(false);
            } else {
                this.hasValidator.emit(true);
            }
        });
    }

    private formatDatetimeToDate(date: Date): string {
        return date.getUTCFullYear() + "-" + ("0" + (date.getUTCMonth() + 1)).slice(-2) + "-" + ("0" + date.getUTCDate()).slice(-2);
    }

    public get minYear(): number {
        return this.data.children.latestYDiff;
    }

    public get maxYear(): number {
        return this.data.children.earliestYDiff;
    }

    public getPlaceholderByIndex(n: number): string {
        return this.placeholder.split(",")[n];
    }

    public isFormDatetimeInvalid(): boolean {
        return !this.formDatetimeGroup.controls["day"].value || !this.formDatetimeGroup.controls["month"].value || !this.formDatetimeGroup.controls["year"].value;
    }

    public isFormDatetimeControlValid(name: string): boolean {
        if (!this.formDatetimeGroup.controls["day"].value || !this.formDatetimeGroup.controls["month"].value || !this.formDatetimeGroup.controls["year"].value) {
            return false;
        }

        return this.formDatetimeGroup.controls[name]?.valid && (this.formDatetimeGroup.controls[name].dirty || this.formDatetimeGroup.controls[name].touched);
    }

    public isFormDatetimeControlInvalid(name: string): boolean {
        if (!this.formDatetimeGroup.controls["day"].value || !this.formDatetimeGroup.controls["month"].value || !this.formDatetimeGroup.controls["year"].value) {
            return false;
        }

        return this.formDatetimeGroup.controls[name]?.invalid && (this.formDatetimeGroup.controls[name].dirty || this.formDatetimeGroup.controls[name].touched);
    }
}
