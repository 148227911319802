<app-blank
    image="header/header-background-10.jpg"
    [title]="metadata.unsubscribe.header.content"
    [isLoading]="isLoadingInstance">
    @if (!isLoadingInstance) {
        <section class="py-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5">
                        <form [formGroup]="formUnsubscribe">
                            @switch (step) {
                                @case (1) {
                                    <p class="mb-3">
                                        {{ metadata.unsubscribe.blankArrivalForm.content }}
                                    </p>

                                    @for (field of fields; track index; let index = $index) {
                                        @if (field.name == 'email') {
                                            <app-input
                                                [type]="field.type"
                                                [name]="field.name"
                                                [prepend]="field.prepend"
                                                [label]="field.label || field.alt_txt"
                                                [placeholder]="field.placeholder"
                                                [helperText]="field.help_txt"
                                                [errorMessage]="field.validation_error"
                                                [options]="field.options"
                                                [paragraph]="field.paragraph"
                                                [headline]="field.headline"
                                                [formGroup]="formUnsubscribe">
                                            </app-input>
                                        }
                                    }

                                    <div class="text-center">
                                        <button
                                            class="btn btn-secondary w-100"
                                            (click)="submitEmail()"
                                            [disabled]="formUnsubscribe.get('email')?.invalid">
                                            {{ metadata.unsubscribe.blankArrivalForm.button }}
                                        </button>
                                    </div>
                                }
                                @case (2) {
                                    <h4 class="mb-3" [innerHTML]="metadata.unsubscribe.main.heading"></h4>
                                    <p class="mb-3" [innerHTML]="metadata.unsubscribe.main.content"></p>

                                    <div class="form-check mb-2">
                                        <input
                                            id="all"
                                            type="checkbox"
                                            class="form-check-input"
                                            formControlName="unsubscribe_all"
                                            (change)="unsubscribeAll()"
                                        >
                                        <label class="form-check-label" for="all">
                                            {{ metadata.meta.selectAllToggle }}
                                        </label>
                                    </div>
                                    <div class="mb-3 ps-3">
                                        <div class="form-check mb-2">
                                            <input
                                                id="sms"
                                                type="checkbox"
                                                class="form-check-input"
                                                formControlName="unsubscribe_sms"
                                            >
                                            <label class="form-check-label" for="sms">
                                                {{ metadata.unsubscribe.checkboxes.unsubscribeSms }}
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                id="email"
                                                type="checkbox"
                                                class="form-check-input"
                                                formControlName="unsubscribe_email"
                                            >
                                            <label class="form-check-label" for="email">
                                                {{ metadata.unsubscribe.checkboxes.unsubscribeEmail }}
                                            </label>
                                        </div>
                                    </div>

                                    <div class="d-flex">
                                        <button
                                            class="btn btn-secondary w-100"
                                            (click)="submitUnsubscribe()"
                                            [disabled]="!formUnsubscribe.get('unsubscribe_sms')?.value && !formUnsubscribe.get('unsubscribe_email')?.value">
                                            {{ metadata.unsubscribe.blankArrivalForm.button }}
                                        </button>
                                    </div>
                                }
                                @case (3) {
                                    <h4
                                        class="mb-3"
                                        [innerHTML]="metadata.unsubscribe.thankYouFeedbackUpper.heading">
                                    </h4>
                                    <p
                                        class="mb-3"
                                        [innerHTML]="metadata.unsubscribe.thankYouFeedbackUpper.content">
                                    </p>

                                    <form [formGroup]="formUnsubscribeReason" class="mb-3">
                                        <div class="d-flex flex-column">
                                            @for (reason of reasons; track index; let index = $index) {
                                                <div class="form-check" [class.mb-2]="reasons.length > index + 1">
                                                    <input
                                                        type="radio"
                                                        autocomplete="off"
                                                        [id]="reason.key"
                                                        [value]="reason.key"
                                                        class="form-check-input"
                                                        formControlName="reason"
                                                    >
                                                    <label class="form-check-label" [for]="reason.key">
                                                        {{ reason.value }}
                                                    </label>
                                                </div>
                                            }
                                            @if (formUnsubscribeReason.get("reason")?.value == "other") {
                                                <app-input-textarea
                                                    name="feedback"
                                                    class="mt-3"
                                                    [formGroup]="formUnsubscribeReason"
                                                />
                                            }
                                        </div>
                                    </form>

                                    <div class="d-flex">
                                        <button
                                            class="btn btn-secondary w-100"
                                            (click)="submitUnsubscribeReason()"
                                            [disabled]="formUnsubscribeReason.invalid">
                                            {{ metadata.unsubscribe.blankArrivalForm.button }}
                                        </button>
                                    </div>
                                }
                                @case (4) {
                                    <h4
                                        class="mb-3"
                                        [innerHTML]="metadata.unsubscribe.thankYouGoodbye.heading">
                                    </h4>
                                    <p
                                        class="mb-3"
                                        [innerHTML]="metadata.unsubscribe.thankYouGoodbye.content">
                                    </p>

                                    <div class="d-flex">
                                        <button
                                            class="btn btn-secondary w-100"
                                            (click)="submitUnsubscribeComplete()">
                                            {{ metadata.unsubscribe.thankYouGoodbye.button }}
                                        </button>
                                    </div>
                                }
                            }
                        </form>
                    </div>
                </div>
            </div>
        </section>
    }
</app-blank>
