import { Component } from "@angular/core";
import { BaseFormInput } from "../base-form-input";

@Component({
    selector: "app-input-textarea",
    templateUrl: "./textarea.component.html",
    styleUrls: ["./textarea.component.scss"],
})
export class FormTextareaComponent extends BaseFormInput {
    public constructor() {
        super();
    }
}
