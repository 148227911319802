<app-layout [isLoading]="isLoadingInstance" [isBlurredNavbar]="true">
    @if (!isLoadingInstance) {
        <header class="application-header">
            <figure class="bg-header" [style]="getBackgroundImageCss('header/header-background.jpg')"></figure>

            <div class="container">
                <section class="content">
                    <div class="row">
                        <div class="col-lg-6 align-items-center d-flex">
                            <h1
                                class="my-lg-0 mt-5 mb-4"
                                [innerHTML]="metadata.applicationKickoff.home_hero.content">
                            </h1>
                        </div>

                        <div class="col-lg-6">
                            <div class="card text-white bg-gradient"
                                 [style]="getBackgroundImageCss('gradients/desktop-main-3.jpg')">
                                <form [formGroup]="formKickOff">
                                    <div class="form-group">
                                        <label
                                            for="amount"
                                            class="form-label w-100 d-flex justify-content-between align-items-center">
                                            <span>
                                                {{ metadata.applicationKickoff.amount_options.children.label }}
                                            </span>

                                            <span class="currency-unit text-secondary fs-5 fw-semibold">
                                                <span class="value">
                                                    {{ separateWithCommas(formKickOff.value.amount) }}
                                                </span>
                                                <span class="unit">
                                                    {{ metadata.applicationKickoff.amount_options.children.currency_unit }}
                                                </span>
                                            </span>
                                        </label>
                                        <input
                                            type="range"
                                            class="form-range"
                                            id="amount"
                                            [value]="amount"
                                            [min]="minAmount"
                                            [max]="maxAmount"
                                            [step]="step"
                                            formControlName="amount"
                                        >
                                    </div>

                                    <div class="form-group">
                                        <label class="form-label">
                                            {{ metadata.applicationKickoff.duration_options.children.label }}
                                        </label>

                                        <div class="d-flex flex-md-row flex-column w-100 gap-2" role="group">
                                            <input
                                                type="radio"
                                                class="btn-check"
                                                name="period"
                                                id="period-1"
                                                [value]="61"
                                                autocomplete="off"
                                                [checked]="true"
                                                formControlName="period"
                                            >
                                            <label
                                                class="btn btn-outline-light w-100"
                                                for="period-1">
                                                61 {{ metadata.applicationKickoff.duration_options.children.unit }}
                                            </label>

                                            <input
                                                type="radio"
                                                class="btn-check"
                                                name="period"
                                                [value]="90"
                                                id="period-2"
                                                autocomplete="off"
                                                formControlName="period"
                                            >
                                            <label
                                                class="btn btn-outline-light w-100"
                                                for="period-2">
                                                90 {{ metadata.applicationKickoff.duration_options.children.unit }}
                                            </label>
                                        </div>
                                    </div>
                                </form>

                                <button
                                    buttonLink
                                    class="btn btn-secondary mt-2"
                                    [buttonLinkDelay]="650"
                                    (buttonLinkCallback)="submitRequestApplication()">
                                    {{ metadata.applicationKickoff.cta_button.content }}
                                </button>

                                <p class="text-center mt-2">
                                    {{ metadata.applicationKickoff.consent_privacy.children?.without_obligation }}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="disclaimer">
                    <p [innerHTML]="metadata.applicationKickoff.consent_privacy.content"></p>
                </section>
            </div>
        </header>

        <section class="py-6">
            <div class="container">
                <header class="text-center mb-4">
                    <h1>
                        {{ metadata.landing.howItWorks.heading }}
                    </h1>
                    <p>
                        {{ metadata.landing.howItWorks.content }}
                    </p>
                </header>

                <section>
                    <div class="row g-4">
                        @for (step of metadata.landing.howItWorks.items; track index; let index = $index) {
                            <div class="col-xl-4">
                                <div class="card h-100">
                                    <div class="card-body h-100 d-flex align-items-center">
                                        <div class="row align-items-center">
                                            <div class="col-5 d-flex justify-content-center">
                                                <img
                                                    class="w-100"
                                                    src="assets/img/{{ domainName }}/decoration/decoration-person-{{ index + 1 }}.svg"
                                                    alt="Complete the online application"
                                                >
                                            </div>
                                            <div class="col-7">
                                                <h6>
                                                    {{ step.heading }}
                                                </h6>
                                                <p>
                                                    {{ step.paragraph }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>

                <footer class="text-center mt-5">
                    <button
                        buttonLink="/solicita"
                        [buttonLinkDelay]="650"
                        class="btn btn-secondary px-5">
                        {{ metadata.landing.howItWorks.ctaButton }}
                    </button>
                </footer>
            </div>
        </section>

        <section class="py-6 bg-light">
            <div class="container">
                <header class="text-center mb-5">
                    <h1>
                        {{ metadata.landing.howItWorksWizard.heading }}
                    </h1>
                    <p>
                        {{ metadata.landing.howItWorksWizard.content }}
                    </p>
                </header>

                <section>
                    <div class="row align-items-center">
                        <div class="col-lg-5">
                            <img
                                class="w-100"
                                src="assets/img/{{ domainName }}/decoration/decoration-screen-{{ stepperRank }}.svg"
                                [alt]="stepperRank"
                            >
                        </div>

                        <div class="col-lg-6 offset-lg-1 mt-lg-0 mt-5">
                            <div class="stepper d-flex flex-column">
                                @for (step of metadata.landing.howItWorksWizard.items; track index; let count = $count; let index = $index) {
                                    <div class="d-flex">
                                        <div class="d-flex flex-column pe-4 align-items-center">
                                            <div
                                                class="stepper-rank"
                                                [class.active]="stepperRank == index + 1"
                                                (click)="changeStepperContent(index + 1)">
                                                {{ index + 1 }}
                                            </div>

                                            @if (count > index + 1) {
                                                <div class="line h-100"></div>
                                            }
                                        </div>

                                        <div class="pb-4 stepper-body" (click)="changeStepperContent(index + 1)">
                                            <h5 class="text-dark">
                                                {{ step.heading }}
                                            </h5>
                                            <p class="pb-3">
                                                {{ step.paragraph }}
                                            </p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <footer class="text-center mt-4">
                    <button
                        buttonLink="/solicita"
                        [buttonLinkDelay]="650"
                        class="btn btn-secondary px-5">
                        {{ metadata.landing.howItWorksWizard.ctaButton }}
                    </button>
                </footer>
            </div>
        </section>

        <section
            class="bg-primary text-white py-6 bg-gradient"
            [style]="getBackgroundImageCss('gradients/desktop-main-2.jpg')">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg mb-lg-0 mb-4 text-lg-start text-center">
                        <h1 class="mb-0">
                            {{ metadata.landing.howMuchDoYouNeed.heading }}
                        </h1>
                    </div>

                    <div class="col-lg">
                        <div class="row">
                            <form [formGroup]="formGetAnOffer" class="col-12">
                                <div class="d-flex flex-sm-row flex-column w-100 gap-2 mb-2" role="group">
                                    @for (option of metadata.landing.howMuchDoYouNeed.options; track option) {
                                        <div class="w-100">
                                            <input
                                                type="radio"
                                                class="btn-check"
                                                name="amount"
                                                [id]="option"
                                                autocomplete="off"
                                                [checked]="true"
                                                [value]="option"
                                                formControlName="amount"
                                            >
                                            <label class="btn btn-outline-light w-100" [for]="option">
                                                <div class="currency-unit">
                                                    <span class="value">
                                                        {{ separateWithCommas(option) }}
                                                    </span>
                                                    <span class="unit">
                                                        {{ metadata.applicationKickoff.amount_options.children.currency_unit }}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                    }
                                </div>
                            </form>
                        </div>

                        <div class="col-12 pt-3 justify-content-center d-flex">
                            <button
                                buttonLink="/solicita"
                                [buttonLinkDelay]="650"
                                [buttonLinkQueryParams]="{
                                    amount: formGetAnOffer.get('amount')?.value,
                                }"
                                class="btn btn-secondary px-5">
                                {{ metadata.landing.howMuchDoYouNeed.ctaButton }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <header class="text-center mb-5">
                    <h1 class="mb-0">
                        {{ metadata.landing.testimonials.heading }}
                    </h1>
                </header>

                <section>
                    <div class="row justify-content-center">
                        <div class="col-xl-9">
                            <div class="row g-4">
                                @for (testimonial of metadata.landing.testimonials.items; track $index) {
                                    <div class="col-lg-4">
                                        <div class="card card-testimonial h-100">
                                            <div class="card-header">
                                                <div class="row align-items-center">
                                                    <div class="col-12">
                                                        <img
                                                            [src]="getTestimonialImage(testimonial.name)"
                                                            class="card-avatar rounded-circle mb-2"
                                                            [alt]="testimonial.name"
                                                        >
                                                    </div>

                                                    <div class="col">
                                                        <h6 class="mb-0">
                                                            {{ testimonial.name }}
                                                        </h6>

                                                        @if (testimonial.location) {
                                                            <small>
                                                                {{ testimonial.location }}
                                                            </small>
                                                        }

                                                        <div>
                                                            @for (rating of rating(testimonial.rating || 5); track rating) {
                                                                <i class="bi bi-star-fill text-primary"></i>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="card-body">
                                                <p>{{ testimonial.body }}</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    }
</app-layout>
