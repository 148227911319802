import { SpainIdEnum } from "../enums/spain-id.enum";

const DNI_REGEX = /^(\d{8})([A-Z])$/;
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

export class SpainUtil {
    /**
     * Ensure uppercase and remove whitespace ang hyphens
     *
     * @param id
     * @private
     */
    private static sanitize(id: string): string {
        return id.toUpperCase();
    }

    public static validateSpanishId(id: string): boolean {
        id = this.sanitize(id);

        let isValid: boolean = false;
        const type: SpainIdEnum | undefined = this.spainIdType(id);

        switch (type) {
            case SpainIdEnum.DNI:
                isValid = this.isValidDNI(id);
                break;
            case SpainIdEnum.NIE:
                isValid = this.isValidNIE(id);
                break;
            case SpainIdEnum.CIF:
                isValid = this.isValidCIF(id);
                break;
        }

        return isValid;
    }

    public static spainIdType(id: string): SpainIdEnum | undefined {
        id = this.sanitize(id);

        if (id.match(DNI_REGEX)) {
            return SpainIdEnum.DNI;
        }
        if (id.match(CIF_REGEX)) {
            return SpainIdEnum.CIF;
        }
        if (id.match(NIE_REGEX)) {
            return SpainIdEnum.NIE;
        }

        return;
    }

    public static isValidDNI(dni: string): boolean {
        dni = this.sanitize(dni);
        const dniLetters = "TRWAGMYFPDXBNJZSQVHLCKE";
        const letter = dniLetters.charAt(parseInt(dni, 10) % 23);

        return letter === dni.charAt(8);
    }

    public static isValidNIE(nie: string): boolean {
        nie = this.sanitize(nie);

        // Change the initial letter for the corresponding number and validate as DNI
        let niePrefix = nie.charAt(0);

        switch (niePrefix) {
            case "X":
                niePrefix = "0";
                break;
            case "Y":
                niePrefix = "1";
                break;
            case "Z":
                niePrefix = "2";
                break;
            default:
                return false;
        }

        return this.isValidDNI(niePrefix + nie.substr(1));
    }

    public static isValidCIF(cif: string): boolean {
        cif = this.sanitize(cif);

        if (!cif || cif.length !== 9) {
            return false;
        }

        const letters: string[] = ["J", "A", "B", "C", "D", "E", "F", "G", "H", "I"];
        const digits: string = cif.substr(1, cif.length - 2);
        const letter: string = cif.substr(0, 1);
        const control: string = cif.substr(cif.length - 1);

        let sum: number = 0;
        let i;
        let digit;

        if (!letter.match(/[A-Z]/)) {
            return false;
        }

        for (i = 0; i < digits.length; ++i) {
            digit = parseInt(digits[i]);

            if (isNaN(digit)) {
                return false;
            }

            if (i % 2 === 0) {
                digit *= 2;
                if (digit > 9) {
                    digit = Math.floor(digit / 10) + (digit % 10);
                }

                sum += digit;
            } else {
                sum += digit;
            }
        }

        sum %= 10;

        if (sum !== 0) {
            digit = 10 - sum;
        } else {
            digit = sum;
        }

        if (letter.match(/[ABEH]/)) {
            return String(digit) === control;
        }

        if (letter.match(/[NPQRSW]/)) {
            return letters[digit] === control;
        }

        return String(digit) === control || letters[digit] === control;
    }
}
