import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

import { Metadata } from "../../services/meta/meta.interface";
import { MetaService } from "../../services/meta/meta.service";

@Component({
    selector: "app-not-found",
    templateUrl: "./not-found.component.html",
    styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
    private _isLoadingInstance: boolean = false;

    public constructor(
        private readonly router: Router,
        private readonly metaService: MetaService,
    ) {
    }

    public ngOnInit(): void {
        this.metaService.setTitleAndDescription(null);
    }

    public get isLoadingInstance(): boolean {
        return this._isLoadingInstance;
    }

    public set isLoadingInstance(isLoadingInstance: boolean) {
        this._isLoadingInstance = isLoadingInstance;
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public async redirectToLandingPage(): Promise<void> {
        await this.router.navigateByUrl("/");
    }
}
