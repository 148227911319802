import { Injectable } from "@angular/core";
import { HttpService } from "../http/http.service";
import { map, Observable, tap } from "rxjs";
import { Route, Router, Routes } from "@angular/router";

import { HowComponent } from "../../instances/how/how.component";
import { FaqComponent } from "../../instances/faq/faq.component";
import { RouteResponse } from "./route.interface";
import { AboutComponent } from "../../instances/about/about.component";
import { ContactComponent } from "../../instances/contact/contact.component";
import { RedirectComponent } from "../../instances/redirect/redirect.component";
import { ShortUrlComponent } from "../../instances/short-url/short-url.component";
import { PendingChangesGuard } from "../../guards/pending-changes.guard";
import { ApplicationComponent } from "../../instances/application/application.component";
import { UnsubscribeComponent } from "../../instances/unsubscribe/unsubscribe.component";
import { PolicyTermsComponent } from "../../instances/policy/terms/policy-terms.component";
import { PolicyPrivacyComponent } from "../../instances/policy/privacy/policy-privacy.component";
import { PolicyCookiesComponent } from "../../instances/policy/cookies/policy-cookies.component";

import { howResolver } from "../../instances/how/how.resolver";
import { faqResolver } from "../../instances/faq/faq.resolver";
import { aboutResolver } from "../../instances/about/about.resolver";
import { contactResolver } from "../../instances/contact/contact.resolver";
import { applicationResolver } from "../../instances/application/application.resolver";
import { unsubscribeResolver } from "../../instances/unsubscribe/unsubscribe.resolver";
import { policyCookiesResolver, policyPrivacyResolver, policyTermsResolver } from "../../instances/policy/policy.resolver";
import { shortUrlResolver } from "../../instances/short-url/short-url.resolver";

@Injectable({
    providedIn: "root",
})
export class RouteService {
    public constructor(
        private readonly router: Router,
        private readonly httpService: HttpService,
    ) {
    }

    public fetchRoutes(): Observable<Routes> {
        return this.httpService.get("/content", { section: "mainpaths" }).pipe(
            map((res: RouteResponse): Routes =>  this.mapper(res)),
            tap((routes: Routes) => this.router.resetConfig(this.mergeRoutes(routes))),
        );
    }

    private mergeRoutes(routes: Routes): Routes {
        routes.push(...this.router.config);
        return routes;
    }

    private mapper(response: RouteResponse): Routes {
        return Object.entries(response.mainpaths.paths.children)
            .map(([name, path]: [string, string]): Route => this.omitOptionsByKey(name, path))
            .filter((route: Route) => route.path);
    }

    private omitOptionsByKey(name: string, path: string): Route {
        path = path.replace("/", "");

        switch (name) {
            case "application_m":
                return {
                    path: `${path}/:path`,
                    component: ShortUrlComponent,
                    resolve: {
                        data: shortUrlResolver,
                    }
                };
            case "redirect":
                return {
                    path: path,
                    component: RedirectComponent,
                    canDeactivate: [PendingChangesGuard],
                };
            case "faq":
                return {
                    path: path,
                    component: FaqComponent,
                    resolve: {
                        metadata: faqResolver,
                    },
                };
            case "contact":
                return {
                    path: path,
                    component: ContactComponent,
                    resolve: {
                        metadata: contactResolver,
                    },
                };
            case "how_it_works":
                return {
                    path: path,
                    component: HowComponent,
                    resolve: {
                        metadata: howResolver,
                    },
                };
            case "about_us":
                return {
                    path: path,
                    component: AboutComponent,
                    resolve: {
                        metadata: aboutResolver,
                    },
                };
            case "application":
                return {
                    path: path,
                    component: ApplicationComponent,
                    canDeactivate: [PendingChangesGuard],
                    resolve: {
                        metadata: applicationResolver,
                    },
                };
            case "unsubscribe":
                return {
                    path: path,
                    component: UnsubscribeComponent,
                    resolve: {
                        metadata: unsubscribeResolver,
                    },
                };
            case "cookie_policy":
                return {
                    path: path,
                    component: PolicyCookiesComponent,
                    resolve: {
                        metadata: policyCookiesResolver,
                    },
                };
            case "privacy_policy":
                return {
                    path: path,
                    component: PolicyPrivacyComponent,
                    resolve: {
                        metadata: policyPrivacyResolver,
                    },
                };
            case "terms_and_conditions":
                return {
                    path: path,
                    component: PolicyTermsComponent,
                    resolve: {
                        metadata: policyTermsResolver,
                    },
                };
            default:
                return {};
        }
    }
}
