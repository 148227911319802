import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
    providedIn: "root",
})
export class HeadService {
    public constructor(
        private readonly meta: Meta,
        private readonly title: Title,
    ) {
    }

    public setPageTitle(title: string): void {
        this.title.setTitle(title);
    }

    public setPageDescription(description: string): void {
        this.meta.updateTag({ name: "description", content: description });
    }
}
