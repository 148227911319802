<div class="input-group flex-column" [formGroup]="formGroup">
    <small class="text-muted mb-1">
        {{ label }}
    </small>

    <div class="d-flex flex-row">
        @for (option of options; track index; let index = $index) {
            <div
                class="w-100"
                [class.me-2]="options.length > index + 1">
                <input
                    [id]="option"
                    type="radio"
                    [value]="option"
                    class="btn-check"
                    autocomplete="off"
                    [class.is-valid]="isFormControlValid"
                    [class.is-invalid]="isFormControlInvalid"
                    [formControlName]="name"
                >
                <label [for]="option" class="btn btn-outline-form w-100">
                    {{ option }}
                </label>
            </div>
        }
    </div>
</div>
