import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UnsubscribeComponent } from "./unsubscribe.component";
import { ComponentsModule } from "../../components/components.module";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        UnsubscribeComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        ReactiveFormsModule,
    ],
})
export class UnsubscribeModule {
}
