import { Component, ContentChild, Input, TemplateRef } from "@angular/core";
import { StorageEnum } from "../../enums/storage.enum";
import { StorageService } from "../../services/storage/storage.service";

@Component({
    selector: "app-blank",
    templateUrl: "./blank.component.html",
    styleUrls: ["./blank.component.scss"],
})
export class BlankComponent {
    private _title!: string;
    private _hasNavbar: boolean = true;
    private _hasFooter: boolean = true;
    private _isLoading: boolean = false;
    private _instanceName!: string;
    @ContentChild("header") header!: TemplateRef<any>;

    @Input()
    public image!: string;

    public constructor(
        private readonly storageService: StorageService,
    ) {
    }

    public getBackgroundImageCss(name: string): string {
        return `background-image: url('assets/img/${this.domainName}/${name}') !important`;
    }

    public get domainName(): string | null {
        return this.storageService.getItem(StorageEnum.DOMAIN_NAME);
    }

    public get title(): string {
        return this._title;
    }

    @Input()
    public set title(title: string) {
        this._title = title;
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }

    @Input()
    public set isLoading(isLoading: boolean) {
        this._isLoading = isLoading;
    }

    public get hasFooter(): boolean {
        return this._hasFooter;
    }

    @Input()
    public set hasFooter(hasFooter: boolean) {
        this._hasFooter = hasFooter;
    }

    public get hasNavbar(): boolean {
        return this._hasNavbar;
    }

    @Input()
    public set hasNavbar(hasNavbar: boolean) {
        this._hasNavbar = hasNavbar;
    }

    public get instanceName(): string {
        return this._instanceName;
    }

    @Input()
    public set instanceName(instanceName: string) {
        this._instanceName = instanceName;
    }
}
