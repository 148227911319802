import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";

import { ContactService } from "./contact.service";
import { ContactMetadata } from "./contact.interface";

export const contactResolver: ResolveFn<ContactMetadata> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const service: ContactService = inject(ContactService);
    return service.getMetadata();
};
