import { Component, OnInit } from "@angular/core";

import { Metadata } from "../../services/meta/meta.interface";
import { MetaService } from "../../services/meta/meta.service";
import { StorageEnum } from "../../enums/storage.enum";
import { InstanceEnum } from "../../enums/instance.enum";
import { LoaderService } from "../../services/loader/loader.service";
import { StorageService } from "../../services/storage/storage.service";

@Component({
    selector: "app-how",
    templateUrl: "./how.component.html",
    styleUrls: ["./how.component.scss"],
})
export class HowComponent implements OnInit {
    private _isInstanceLoading: boolean = true;

    public constructor(
        private readonly metaService: MetaService,
        private readonly loaderService: LoaderService,
        private readonly storageService: StorageService,
    ) {
    }

    public ngOnInit(): void {
        this.loaderService.getInstanceLoader(this.instanceName).subscribe((res) => {
            this.isInstanceLoading = res.isLoading;
        });
    }

    public get isInstanceLoading(): boolean {
        return this._isInstanceLoading;
    }

    public set isInstanceLoading(isInstanceLoading: boolean) {
        this._isInstanceLoading = isInstanceLoading;
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public get instanceName(): InstanceEnum {
        return InstanceEnum.HOW;
    }

    public get domainName(): string | null {
        return this.storageService.getItem(StorageEnum.DOMAIN_NAME);
    }
}
