import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { HowService } from "./how.service";
import { HowMetadata } from "./how.interface";

export const howResolver: ResolveFn<HowMetadata> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const service: HowService = inject(HowService);
    return service.getMetadata();
};
