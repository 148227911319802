<footer class="pt-5 pb-3">
    <div class="container">
        <div class="row">
            <div class="col mb-3">
                <a routerLink="/" class="footer-brand d-flex align-items-center mb-3 link-dark text-decoration-none">
                    <img src="assets/img/{{ domainName }}/logo/logo-dark.svg" [alt]="domainName">
                </a>
                <p class="text-muted text-capitalize-first-letter">{{ domainName }} &copy; {{ year }}</p>
            </div>

            <div class="col-lg-4 d-flex mb-3 ms-auto">
                <ul class="nav flex-column w-100">
                    @for (link of mainNavLinks; track index; let index = $index) {
                        <li class="nav-item my-1">
                            <a
                                class="nav-link p-0 text-muted"
                                [routerLink]="link.url"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">
                                {{ link.label }}
                            </a>
                        </li>
                    }
                </ul>
            </div>

            <div class="col-lg-4 d-flex mb-3 ms-auto">
                <ul class="nav flex-column w-100">
                    @for (link of legalLinks; track index; let index = $index) {
                        <li class="nav-item my-1">
                            <a
                                class="nav-link p-0 text-muted"
                                [routerLink]="link.url"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">
                                {{ link.label }}
                            </a>
                        </li>
                    }
                </ul>
            </div>
        </div>
    </div>
</footer>
