<app-blank
    image="header/header-background-7.jpg"
    [title]="metadata.howItWorks.main.content"
    [isLoading]="isInstanceLoading"
    [instanceName]="instanceName">
    @if (!isInstanceLoading) {
        <section class="py-5">
            <div class="container">
                <div class="row g-md-7 g-5 justify-content-center">
                    @for (item of metadata.howItWorks.main.items; track $index;) {
                        <div class="col-lg-8 col-md-10">
                            <div class="row g-5 align-items-center">
                                <div class="col-md-auto" [class.order-md-last]="$index % 2 == 1">
                                    <img
                                        class="w-100"
                                        src="assets/img/{{ domainName }}/decoration/decoration-person-{{ $index + 1 }}.svg"
                                        [alt]="domainName"
                                    >
                                </div>
                                <div class="col-md">
                                    <h2>
                                        {{ item.header }}
                                    </h2>
                                    <p>
                                        {{ item.paragraph }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div class="d-flex justify-content-center text-center mt-5">
                    <div>
                        <button
                            buttonLink="/solicita"
                            [buttonLinkDelay]="650"
                            class="btn btn-secondary px-5 mt-4">
                            {{ metadata.howItWorks.main.ctaButton }}
                        </button>

                        <div class="mt-2">
                            {{ metadata.howItWorks.main.ctaAfter }}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
</app-blank>
