import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, Input, PLATFORM_ID } from "@angular/core";
import { MetaService } from "../../services/meta/meta.service";

@Component({
    selector: "app-layout",
    templateUrl: "./layout.component.html",
    styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent {
    private _hasNavbar: boolean = true;
    private _hasFooter: boolean = true;
    private _isLoading: boolean = true;
    private _isBlurredNavbar: boolean = false;

    public constructor(
        private readonly metaService: MetaService,
        @Inject(PLATFORM_ID) private readonly platformId: Object,
    ) {
    }

    public get isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    public get hasFooter(): boolean {
        return this._hasFooter;
    }

    @Input()
    public set hasFooter(hasFooter: boolean) {
        this._hasFooter = hasFooter;
    }

    public get hasNavbar(): boolean {
        return this._hasNavbar;
    }

    @Input()
    public set hasNavbar(hasNavbar: boolean) {
        this._hasNavbar = hasNavbar;
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }

    @Input()
    public set isLoading(isLoading: boolean) {
        this._isLoading = isLoading;
    }

    public get isBlurredNavbar(): boolean {
        return this._isBlurredNavbar;
    }

    @Input()
    public set isBlurredNavbar(isBlurredNavbar: boolean) {
        this._isBlurredNavbar = isBlurredNavbar;
    }
}
