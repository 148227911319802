import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShortUrlComponent } from "./short-url.component";
import { ComponentsModule } from "../../components/components.module";

@NgModule({
    declarations: [
        ShortUrlComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
    ],
})
export class ShortUrlModule {
}
