import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ComponentsModule } from "../../components/components.module";
import { PendingChangesGuard } from "../../guards/pending-changes.guard";
import { ApplicationComponent } from "./application.component";
import { ButtonLinkDirective } from "../../directives/button-link.directive";

@NgModule({
    declarations: [
        ApplicationComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        ComponentsModule,
        ReactiveFormsModule,
        FormsModule,
        ButtonLinkDirective,
    ],
    providers: [
        PendingChangesGuard,
    ],
})
export class ApplicationModule {
}
