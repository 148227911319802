import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ContactComponent } from "./contact.component";
import { ComponentsModule } from "../../components/components.module";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        ContactComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        ReactiveFormsModule,
    ],
})
export class ContactModule {
}
