<div class="modal-body">
    {{ metadata.application.inProgressModal.heading }}
</div>

<div class="modal-footer py-3 justify-content-center">
    <button class="btn btn-outline-dark" (click)="leave()">
        {{ metadata.application.inProgressModal.leaveButton }}
    </button>
    <button class="btn btn-secondary" (click)="continue()">
        {{ metadata.application.inProgressModal.continueButton }}
    </button>
</div>
