import { Injectable } from "@angular/core";
import { HttpService } from "../../services/http/http.service";
import { MetaService } from "../../services/meta/meta.service";
import { map, Observable, tap } from "rxjs";
import { RequestEndpointEnum } from "../../enums/request-endpoint.enum";
import { HowMetadata, HowMetadataResponse } from "./how.interface";
import { InstanceEnum } from "../../enums/instance.enum";

@Injectable({
    providedIn: "root",
})
export class HowService {
    public constructor(
        private readonly httpService: HttpService,
        private readonly metaService: MetaService,
    ) {
    }

    public getMetadata(): Observable<HowMetadata> {
        return this.httpService.get("/content", { section: RequestEndpointEnum.HOW })
            .pipe(
                map((res: HowMetadataResponse) => this.mapper(res)),
                tap((res: HowMetadata) => this.metaService.setMetadata<HowMetadata>(res, InstanceEnum.HOW)),
            );
    }

    private mapper(response: HowMetadataResponse): HowMetadata {
        return {
            meta: {
                title: response.how_it_works.meta.children.title,
                description: response.how_it_works.meta.children.desc,
            },
            main: {
                content: response.how_it_works.hero_text.content,
                ctaAfter: response.how_it_works.main_content.children.cta_after,
                ctaButton: response.how_it_works.main_content.children.cta_button,
                items: response.how_it_works.main_content.children.steps,
            },
            aboutReceivedUrl: response.how_it_works.about_received_url.content,
        };
    }
}
