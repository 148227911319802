import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class UnnaxService {
    public operations(message: string): void {
        console.log(message)
        switch (message) {
            case "unnax:start_widget:done":
            case "unnax:widget_start:done":
                return;
            case "unnax:aggregation:bank_selection_view:done":
                return;
            case "unnax:bank_login:done":
                return;
            case "unnax:bank_login:fail":
                return;
            case "unnax:aggregation:done":
                return;
            case "unnax:aggregation:fail":
                return;
            default:
                break;
        }
    }
}
