import { Component, OnInit } from "@angular/core";
import { MetaService } from "../../services/meta/meta.service";
import { StorageEnum } from "../../enums/storage.enum";
import { StorageService } from "../../services/storage/storage.service";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    private _legalLinks: any[] = [];
    private _mainNavLinks: any[] = [];

    public constructor(
        private readonly metaService: MetaService,
        private readonly storageService: StorageService,
    ) {
    }

    public ngOnInit(): void {
        this.getNav();
    }

    private getNav(): void {
        this.metaService.header.navbar.children.navLinks.map((navLink: { url: string; label: string; }) => {
            this.mainNavLinks.push({
                url: navLink.url,
                label: navLink.label,
            });
        });

        this.metaService.footer.sec_nav.children.map((navLink: { url: string; label: string; }) => {
            this.legalLinks.push({
                url: navLink.url,
                label: navLink.label,
            });
        });
    }

    public get year(): number {
        return new Date().getFullYear();
    }

    public get mainNavLinks(): any[] {
        return this._mainNavLinks;
    }

    public set mainNavLinks(links: any[]) {
        this._mainNavLinks = links;
    }

    public get legalLinks(): any[] {
        return this._legalLinks;
    }

    public set legalLinks(legalLinks: any[]) {
        this._legalLinks = legalLinks;
    }

    public get domainName(): string | null {
        return this.storageService.getItem(StorageEnum.DOMAIN_NAME);
    }
}
