import { FormGroup } from "@angular/forms";
import { Component, OnInit } from "@angular/core";

import { DataUtil } from "../../utils/data.util";
import { Metadata } from "../../services/meta/meta.interface";
import { StorageEnum } from "../../enums/storage.enum";
import { MetaService } from "../../services/meta/meta.service";
import { InstanceEnum } from "../../enums/instance.enum";
import { LoaderService } from "../../services/loader/loader.service";
import { StorageService } from "../../services/storage/storage.service";
import { ApplicationService } from "../application/application.service";
import { RequestEndpointEnum } from "../../enums/request-endpoint.enum";

@Component({
    selector: "app-landing",
    templateUrl: "./landing.component.html",
    styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
    private _stepperRank: number = 1;
    private _isLoadingInstance: boolean = false;

    public constructor(
        private readonly metaService: MetaService,
        private readonly loaderService: LoaderService,
        private readonly storageService: StorageService,
        private readonly applicationService: ApplicationService,
    ) {
    }

    public ngOnInit(): void {
        this.metaService.setTitleAndDescription(RequestEndpointEnum.HOME);
        this.loaderService.getInstanceLoader(this.instanceName).subscribe((res) => {
            this.isLoadingInstance = res.isLoading;
        });
    }

    public async submitRequestApplication(): Promise<void> {
        this.storageService.setItem(StorageEnum.IS_APPLICATION_STARTED, true);
        await this.applicationService.submitRequestApplication();
    }

    public changeStepperContent(number: number): void {
        this.stepperRank = number;
    }

    public get amount(): number {
        return this.applicationService.defaultAmount;
    }

    public get minAmount(): number {
        return this.applicationService.minAmount;
    }

    public get maxAmount(): number {
        return this.applicationService.maxAmount;
    }

    public get period(): number {
        return this.applicationService.period;
    }

    public set period(period: number) {
        this.applicationService.period = period;
    }

    public get formKickOff(): FormGroup {
        return this.applicationService.formKickOff;
    }

    public get stepperRank(): number {
        return this._stepperRank;
    }

    public set stepperRank(stepperImage: number) {
        this._stepperRank = stepperImage;
    }

    public get formGetAnOffer(): FormGroup {
        return this.applicationService.formGetAnOffer;
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public get instanceName(): InstanceEnum {
        return InstanceEnum.LANDING;
    }

    public get isLoadingInstance(): boolean {
        return this._isLoadingInstance;
    }

    public set isLoadingInstance(isLoadingInstance: boolean) {
        this._isLoadingInstance = isLoadingInstance;
    }

    public get domainName(): string | null {
        return this.storageService.getItem(StorageEnum.DOMAIN_NAME);
    }

    public rating(rating: number = 5): number[] {
        return Array.from(Array(rating).keys());
    }

    public getTestimonialImage(name: string): string {
        return `assets/img/${this.domainName}/testimonials/${name.replace(/[,. -]/g, "")}.jpg`;
    }

    public getBackgroundImageCss(name: string): string {
        return `background-image: url('assets/img/${this.domainName}/${name}') !important`;
    }

    public separateWithCommas(amount: number): string {
        return DataUtil.commaSeparatedAmount(amount);
    }

    public get step(): number {
        return this.applicationService.step || Math.pow(10, Math.floor(Math.log10(this.maxAmount - this.minAmount) - 1));
    }
}
