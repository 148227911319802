<div class="form-floating" [formGroup]="formGroup">
    <input
        [id]="name"
        type="text"
        class="form-control"
        [placeholder]="placeholder"
        [class.is-valid]="isFormControlValid"
        [class.is-invalid]="isFormControlInvalid"
        [formControlName]="name"
        (input)="onChange($event)"
        [prefix]="prepend"
        [mask]="mask"
        [dropSpecialCharacters]="false"
    >
    <label [for]="name">
        <span [innerHTML]="label"></span>
    </label>
</div>
