import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PolicyPrivacyComponent } from "./policy-privacy.component";
import { ComponentsModule } from "../../../components/components.module";

@NgModule({
    declarations: [
        PolicyPrivacyComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
    ],
})
export class PolicyPrivacyModule {
}
