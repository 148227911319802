import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LandingComponent } from "./landing.component";
import { ComponentsModule } from "../../components/components.module";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { ButtonLinkDirective } from "../../directives/button-link.directive";

@NgModule({
    declarations: [
        LandingComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        ComponentsModule,
        ReactiveFormsModule,
        ButtonLinkDirective,
    ],
})
export class LandingModule {
}
