import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

import { MemoryStorage } from "./memory-storage.service";

@Injectable({
    providedIn: "root",
})
export class StorageService implements Storage {
    private readonly storage: Storage;

    public constructor(
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        if (isPlatformBrowser(this.platformId) && window?.localStorage) {
            this.storage = window.localStorage;
        } else {
            this.storage = new MemoryStorage();
        }
    }

    public get length(): number {
        return this.storage.length;
    }

    public clear(): void {
        this.storage.clear();
    }

    public getItem(key: string): string | null {
        return this.storage.getItem(key);
    }

    public key(index: number): string | null {
        return this.storage.key(index);
    }

    public removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    public setItem(key: string, value: string | number | boolean): void {
        this.storage.setItem(key, value.toString());
    }
}
