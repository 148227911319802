import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";

import { AboutService } from "./about.service";
import { AboutMetadata } from "./about.interface";

export const aboutResolver: ResolveFn<AboutMetadata> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const service: AboutService = inject(AboutService);
    return service.getMetadata();
};
