import { Component } from "@angular/core";
import { SsrCookieService } from "ngx-cookie-service-ssr";

import { DateUtil } from "../../utils/date.util";
import { Metadata } from "../../services/meta/meta.interface";
import { CookieEnum } from "../../enums/cookie.enum";
import { MetaService } from "../../services/meta/meta.service";

@Component({
    selector: "app-tos",
    templateUrl: "./tos.component.html",
    styleUrls: ["./tos.component.scss"],
})
export class TosComponent {
    public constructor(
        private readonly metaService: MetaService,
        private readonly cookieService: SsrCookieService,
    ) {
    }

    public agreeTos(): void {
        this.cookieService.set(CookieEnum.TOS_ID, String(DateUtil.getCurrentUnixTimestamp()), parseInt(this.metadata.footer.cookie_modal.children.ageD));
    }

    public get isTosAgreed(): boolean {
        return !!this.cookieService.get(CookieEnum.TOS_ID);
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }
}
