<app-blank
    image="header/header-background-3.jpg"
    [title]="metadata.faq.header"
    [isLoading]="isLoadingInstance"
    [instanceName]="instanceName">
    @if (!isLoadingInstance) {
        <section>
            <ng-template #header>
                <header class="search mt-2">
                    <div class="input-group">
                        <div class="form-floating">
                            <input
                                id="faq"
                                type="text"
                                class="form-control"
                                (input)="searchFaqByQuestion($event)"
                                [placeholder]="metadata.faq.search.placeholder"
                            >
                            <label for="faq">
                                <span>{{ metadata.faq.search.placeholder }}</span>
                            </label>
                        </div>

                        <button class="btn btn-outline-dark btn-search" type="button" id="search-faq">
                            <i class="bi bi-search"></i>
                        </button>
                    </div>
                </header>
            </ng-template>

            <section class="py-5">
                <div class="container">
                    <div ngbAccordion>
                        @for (question of questionsReplication; track index; let index = $index) {
                            <div ngbAccordionItem>
                                <h2 ngbAccordionHeader>
                                    <button ngbAccordionButton>
                                        <div [innerHTML]="question.question"></div>
                                    </button>
                                </h2>
                                <div ngbAccordionCollapse>
                                    <div ngbAccordionBody>
                                        <div [innerHTML]="question.answer"></div>
                                    </div>
                                </div>
                            </div>
                        } @empty {
                            <div class="py-6 text-center">
                                <h4>{{ metadata.faq.search.noResults }}</h4>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </section>
    }
</app-blank>
