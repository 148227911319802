import { filter } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Metadata } from "../../services/meta/meta.interface";
import { FormField } from "../application/application.interface";
import { FormService } from "../../services/form/form.service";
import { HttpService } from "../../services/http/http.service";
import { MetaService } from "../../services/meta/meta.service";
import { InstanceEnum } from "../../enums/instance.enum";
import { LoaderService } from "../../services/loader/loader.service";
import { RequestEndpointEnum } from "../../enums/request-endpoint.enum";

@Component({
    selector: "app-unsubscribe",
    templateUrl: "./unsubscribe.component.html",
    styleUrls: ["./unsubscribe.component.scss"],
})
export class UnsubscribeComponent implements OnInit {
    private _step!: number;
    private _fields!: FormField[];
    private _formUnsubscribe!: FormGroup;
    private _isLoadingInstance: boolean = true;
    private _formUnsubscribeReason!: FormGroup;
    private _reasons: { key: string; value: string; }[] = [];

    public constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
        private readonly formService: FormService,
        private readonly httpService: HttpService,
        private readonly metaService: MetaService,
        private readonly loaderService: LoaderService,
    ) {
        this.formUnsubscribe = this.formBuilder.group({
            unsubscribe_all: [false],
            unsubscribe_sms: [false],
            unsubscribe_email: [false],
        });

        this.formUnsubscribeReason = this.formBuilder.group({
            reason: [null, [
                Validators.required,
            ]],
            feedback: [""],
        });
    }

    public ngOnInit(): void {
        this.setFormValues();

        this.loaderService.getInstanceLoader(this.instanceName).subscribe((res) => {
            this.isLoadingInstance = res.isLoading;
        });

        this.formUnsubscribe.valueChanges.subscribe((value) => {
            if (value.unsubscribe_sms && value.unsubscribe_email && !value.unsubscribe_all) {
                this.formUnsubscribe.patchValue({
                    unsubscribe_all: true,
                });
            } else if ((!value.unsubscribe_sms || !value.unsubscribe_email) && value.unsubscribe_all) {
                this.formUnsubscribe.patchValue({
                    unsubscribe_all: false,
                });
            }
        });

        const data = [];

        const reasons = this.shuffle(Object.keys(this.metadata.unsubscribe.thankYouFeedbackLower.children.feedback_options).map((key) => {
            return { key: key, value: this.metadata.unsubscribe.thankYouFeedbackLower.children.feedback_options[key] };
        }));

        let other: any;

        for (let reason of reasons) {
            if (reason.key == "other") {
                other = reason;
            } else {
                data.push(reason);
            }
        }

        data.push(other);

        this.reasons = data;
    }

    private setFormValues(): void {
        this.route.queryParams.subscribe((params: Params) => {
            if (params["email"]) {
                this.step = 2;
            } else {
                this.step = 1;
            }

            // TODO: Refactor a lot of hard coded stuff due to lack of API.
            if (!this.metadata.unsubscribe) {
                this.loaderService.instanceLoader$.pipe(filter(instance => instance.name == RequestEndpointEnum.UNSUBSCRIBE && !instance.isLoading)).subscribe(() => {
                    this.fields = [{
                        type: "text",
                        name: this.metadata.unsubscribe.blankArrivalForm.field.name || "email",
                        label: this.metadata.unsubscribe.blankArrivalForm.field.label,
                        validation_error: this.metadata.unsubscribe.blankArrivalForm.field.validationError,
                        validation_regexp: this.metadata.unsubscribe.blankArrivalForm.field.validationRegex,
                    } as any];
                    this.formService.mapFormControlsAndValidators(this.formUnsubscribe, this.fields);
                });
            } else {
                this.fields = [{
                    type: "text",
                    name: this.metadata.unsubscribe.blankArrivalForm.field.name || "email",
                    label: this.metadata.unsubscribe.blankArrivalForm.field.label,
                    validation_error: this.metadata.unsubscribe.blankArrivalForm.field.validationError,
                    validation_regexp: this.metadata.unsubscribe.blankArrivalForm.field.validationRegex,
                } as any];
                this.formService.mapFormControlsAndValidators(this.formUnsubscribe, this.fields);
            }

            this.formUnsubscribe.patchValue({
                email: params["email"],
            });
        });
    }

    public submitEmail(): void {
        this.step = 2;
    }

    public submitUnsubscribe(): void {
        this.httpService.post("/user/unsubscribe/do", {
            instructions: {
                unsubscribe_sms: this.formUnsubscribe.value.unsubscribe_sms,
                unsubscribe_email: this.formUnsubscribe.value.unsubscribe_email,
            }, query_params: "?email=" + this.formUnsubscribe.value.email,
        }).subscribe({
            next: async (): Promise<void> => {
                this.step = 3;
            },
        });
    }

    public unsubscribeAll(): void {
        if (!this.formUnsubscribe.get("unsubscribe_all")?.value) {
            this.formUnsubscribe.patchValue({
                unsubscribe_all: true,
                unsubscribe_sms: true,
                unsubscribe_email: true,
            });
        } else {
            this.formUnsubscribe.patchValue({
                unsubscribe_all: false,
                unsubscribe_sms: false,
                unsubscribe_email: false,
            });
        }
    }

    public submitUnsubscribeReason(): void {
        this.httpService.post("/user/unsubscribe/feedback", {
            instructions: {
                feedback_option_selected: this.formUnsubscribeReason.value.reason,
                feedback_text: this.formUnsubscribeReason.value.feedback,
            }, query_params: "?email=" + this.formUnsubscribe.value.email,
        }).subscribe({
            next: async (): Promise<void> => {
                this.step = 4;
            },
        });
    }

    public async submitUnsubscribeComplete(): Promise<void> {
        await this.router.navigateByUrl("/");
    }

    public shuffle<T>(array: T[]): T[] {
        let currentIndex: number = array.length, randomIndex;

        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }

        return array;
    };

    public get formUnsubscribeReason(): FormGroup {
        return this._formUnsubscribeReason;
    }

    public set formUnsubscribeReason(formUnsubscribeReason: FormGroup) {
        this._formUnsubscribeReason = formUnsubscribeReason;
    }

    public get formUnsubscribe(): FormGroup {
        return this._formUnsubscribe;
    }

    public set formUnsubscribe(formUnsubscribe: FormGroup) {
        this._formUnsubscribe = formUnsubscribe;
    }

    public get fields(): FormField[] {
        return this._fields;
    }

    public set fields(fields: FormField[]) {
        this._fields = fields;
    }

    public get step(): number {
        return this._step;
    }

    public set step(step: number) {
        this._step = step;
    }

    public moveToStep(number: number): void {
        this.step = number;
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public get instanceName(): InstanceEnum {
        return InstanceEnum.UNSUBSCRIBE;
    }

    public get isLoadingInstance(): boolean {
        return this._isLoadingInstance;
    }

    public set isLoadingInstance(isLoadingInstance: boolean) {
        this._isLoadingInstance = isLoadingInstance;
    }

    public get reasons(): { key: string; value: string; }[] {
        return this._reasons;
    }

    public set reasons(reasons: { key: string; value: string; }[]) {
        this._reasons = reasons;
    }

}
