export enum StorageEnum {
    METADATA = "metadata",
    DOMAIN_NAME = "domain_name",
    APPLICATION_AMOUNT = "application_amount",
    APPLICATION_PERIOD = "application_period",
    APPLICATION_FIELDS = "application_fields",
    APPLICATION_SEQUENCE = "application_sequence",
    APPLICATION_PROGRESS = "application_progress",
    IS_APPLICATION_STARTED = "is_application_started",
    APPLICATION_PROGRESS_NOT_STARTED_VALUE = "0",
    APPLICATION_PROGRESS_STARTED_VALUE = "1",
    IGNORE_PENDING_CHANGES = "ignore_pending_changes",
    PAGE_LOADED_AT = "page_loaded_at",
    APPLICATION_UPDATED_AT = "application_updated_at",
    APPLICATION_AMOUNT_SELECTED_AT = "application_amount_selected_at",
    APPLICATION_PERIOD_SELECTED_AT = "application_period_selected_at",
    APPLICATION_EXPIRES_AT = "application_expires_at",
    IS_APPLICATION_RETURNED = "is_application_returned",
}
