import { Component, OnInit } from "@angular/core";

import { Metadata } from "../../services/meta/meta.interface";
import { FaqService } from "./faq.service";
import { MetaService } from "../../services/meta/meta.service";
import { InstanceEnum } from "../../enums/instance.enum";
import { LoaderService } from "../../services/loader/loader.service";

export type FaqQuestion = {
    answer: string;
    question: string;
}

@Component({
    selector: "app-faq",
    templateUrl: "./faq.component.html",
    styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {
    private _isLoadingInstance: boolean = true;
    private _questionsReplication!: FaqQuestion[];

    public constructor(
        private readonly faqService: FaqService,
        private readonly metaService: MetaService,
        private readonly loaderService: LoaderService,
    ) {
        this.questionsReplication = this.questions;
    }

    public ngOnInit(): void {
        this.loaderService.getInstanceLoader(this.instanceName).subscribe((res) => {
            this.isLoadingInstance = res.isLoading;
        });
    }

    public get questions(): FaqQuestion[] {
        return this.faqService.questions;
    }

    public get questionsReplication(): FaqQuestion[] {
        return this._questionsReplication;
    }

    public set questionsReplication(questionsReplication: FaqQuestion[]) {
        this._questionsReplication = questionsReplication;
    }

    public searchFaqByQuestion(event: any): void {
        this.questionsReplication = this.faqService.searchFaqByQuestion(event.target.value, this.questions);
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public get instanceName(): InstanceEnum {
        return InstanceEnum.FAQ;
    }

    public get isLoadingInstance(): boolean {
        return this._isLoadingInstance;
    }

    public set isLoadingInstance(isLoadingInstance: boolean) {
        this._isLoadingInstance = isLoadingInstance;
    }
}
