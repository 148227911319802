import { FormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { ActivatedRoute, Params } from "@angular/router";
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { Metadata } from "../../services/meta/meta.interface";
import { DataUtil } from "../../utils/data.util";
import { StorageEnum } from "../../enums/storage.enum";
import { MetaService } from "../../services/meta/meta.service";
import { InstanceEnum } from "../../enums/instance.enum";
import { LoaderService } from "../../services/loader/loader.service";
import { StorageService } from "../../services/storage/storage.service";
import { ApplicationService } from "./application.service";
import { ApplicationRedirectData, FormField } from "./application.interface";
import { ModalService } from "../../services/modal/modal.service";
import { ModalReturnedComponent } from "../../components/modal/returned/modal-returned.component";
import { NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { RequestEndpointEnum } from "../../enums/request-endpoint.enum";
import { HttpService } from "../../services/http/http.service";

@Component({
    selector: "app-application",
    templateUrl: "./application.component.html",
    styleUrls: ["./application.component.scss"],
})
export class ApplicationComponent implements OnInit, OnDestroy {
    private _isLoadingInstance: boolean = true;
    private _canDeactivateInstance: boolean = false;
    @ViewChild("scrollToElementRef") public scrollToElementRef!: ElementRef;

    public constructor(
        private readonly route: ActivatedRoute,
        private readonly config: NgbModalConfig,
        private readonly metaService: MetaService,
        private readonly modalService: ModalService,
        private readonly loaderService: LoaderService,
        private readonly storageService: StorageService,
        private readonly applicationService: ApplicationService,
    ) {
    }

    public ngOnInit(): void {
        this.loaderService.getInstanceLoader(this.instanceName).subscribe((res): void => {
            this.isLoadingInstance = res.isLoading;
        });

        if (this.isApplicationReturned != undefined || this.isApplicationReturned != null) {
            this.openReturnedModal();
        }

        if (this.isApplicationReturned) {
            this.returnApplication();
        } else {
            this.route.queryParams.subscribe((params: Params): void => {
                const amount: number = Number(params["amount"]);

                if (amount >= this.minAmount && amount <= this.maxAmount) {
                    this.amount = amount;

                    this.formKickOff.patchValue({
                        amount: amount,
                    });

                    if (this.applicationProgress == StorageEnum.APPLICATION_PROGRESS_STARTED_VALUE) {
                        this.hasPendingApplicationWarning = true;
                    }
                }
            });
        }
    }

    public openReturnedModal(): Observable<boolean> {
        this.config.backdrop = "static";
        this.config.keyboard = false;
        const result: Subject<boolean> = new Subject<boolean>();

        this.modalService.open(ModalReturnedComponent).result.then(({ redirect }): void => {
            result.next(redirect);
        }, () => {
        });

        return result.asObservable();
    }

    public ngOnDestroy(): void {
        this.applicationService.resetFormKickOff();
        this.storageService.removeItem(StorageEnum.IS_APPLICATION_STARTED);
    }

    public submitApplication(): void {
        if (this.formApplication.invalid) {
            Object.keys(this.formApplication.controls).forEach((key: string) => {
                const controlErrors = this.formApplication.get(key)?.errors;
                if (controlErrors != null) {
                    this.formApplication.get(key)?.markAllAsTouched();
                    this.formApplication.get(key)?.markAsDirty();
                }
            });
        } else {
            this.applicationService.updateApplication(this.formApplication);
            // TODO: Refactor
            setTimeout(() => {
                this.scrollToElementRef.nativeElement.scrollIntoView();
            }, 1000);
        }
    }

    public get isLoading(): boolean {
        return this.applicationService.isLoading;
    }

    public returnApplication(): void {
        this.applicationService.returnApplication();
    }

    public continueApplication(): void {
        this.applicationService.updateApplication(this.formApplication, true);
        // TODO: Refactor
        setTimeout((): void => {
            this.scrollToElementRef.nativeElement.scrollIntoView();
        }, 1000);
    }

    public async requestNewApplication(): Promise<void> {
        // If application has been started on home page.
        if (Boolean(this.storageService.getItem(StorageEnum.IS_APPLICATION_STARTED))) {
            this.storageService.setItem(StorageEnum.APPLICATION_PROGRESS, StorageEnum.APPLICATION_PROGRESS_NOT_STARTED_VALUE);
            await this.applicationService.submitRequestApplication();
            this.storageService.removeItem(StorageEnum.IS_APPLICATION_STARTED);
        } else {
            this.storageService.setItem(StorageEnum.APPLICATION_PROGRESS, StorageEnum.APPLICATION_PROGRESS_NOT_STARTED_VALUE);
            this.applicationService.resetRequestedApplication();
            this.applicationService.isApplicationReturned = null;
            this.applicationService.hasPendingApplicationWarning = false;
        }
    }

    public get isIframeStep(): boolean {
        return this.applicationService.isIframeStep;
    }

    @HostListener("window:beforeunload")
    private canDeactivate(): Observable<boolean> | boolean {
        return this.canDeactivateInstance;
    }

    public get formApplication(): FormGroup {
        return this.applicationService.formApplication;
    }

    public get fields(): FormField[] {
        return this.applicationService.fields;
    }

    public get formKickOff(): FormGroup {
        return this.applicationService.formKickOff;
    }

    public set amount(amount: number) {
        this.applicationService.amount = amount;
    }

    public get amount(): number {
        return this.applicationService.amount;
    }

    public get minAmount(): number {
        return this.applicationService.minAmount;
    }

    public get maxAmount(): number {
        return this.applicationService.maxAmount;
    }

    public get step(): number {
        return this.applicationService.step || Math.pow(10, Math.floor(Math.log10(this.maxAmount - this.minAmount) - 1));
    }

    public get period(): number {
        return this.applicationService.period;
    }

    public get applicationProgress(): string | null {
        return this.applicationService.applicationProgress;
    }

    public get redirectData(): ApplicationRedirectData {
        return this.applicationService.redirectData;
    }

    public set hasPendingApplicationWarning(hasPendingApplicationWarning: boolean) {
        this.applicationService.hasPendingApplicationWarning = hasPendingApplicationWarning;
    }

    public get hasPendingApplicationWarning(): boolean {
        return this.applicationService.hasPendingApplicationWarning;
    }

    public async submitRequestApplication(): Promise<void> {
        await this.applicationService.submitRequestApplication();
    }

    protected get canDeactivateInstance(): boolean {
        return this._canDeactivateInstance;
    }

    protected set canDeactivateInstance(canDeactivate: boolean) {
        this._canDeactivateInstance = canDeactivate;
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public get instanceName(): InstanceEnum {
        return InstanceEnum.APPLICATION;
    }

    public get isLoadingInstance(): boolean {
        return this._isLoadingInstance;
    }

    public set isLoadingInstance(isLoadingInstance: boolean) {
        this._isLoadingInstance = isLoadingInstance;
    }

    public getData(field: FormField): object {
        return {
            options: field.options,
            children: field.children,
            placeholder: field.placeholder,
        };
    }

    public get isNotStartedApplication(): boolean {
        return this.applicationService.isNotStartedApplication;
    }

    public get isStartedApplication(): boolean {
        return this.applicationService.isStartedApplication;
    }

    public separateWithCommas(amount: number): string {
        return DataUtil.commaSeparatedAmount(amount);
    }

    public get isApplicationReturned(): boolean | null {
        return this.applicationService.isApplicationReturned;
    }
}
