import { FormGroup } from "@angular/forms";
import { Component, Input } from "@angular/core";

@Component({
    selector: "app-form-helper",
    templateUrl: "./helper.component.html",
    styleUrls: ["./helper.component.scss"],
})
export class FormHelperComponent {
    private _formGroup!: FormGroup;
    private _helperText!: string;

    public get formGroup(): FormGroup {
        return this._formGroup;
    }

    @Input()
    public set formGroup(formGroup: FormGroup) {
        this._formGroup = formGroup;
    }

    public get helperText(): string {
        return this._helperText;
    }

    @Input()
    public set helperText(helperText: string) {
        this._helperText = helperText;
    }
}
