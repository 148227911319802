import { Component, Input, OnInit } from "@angular/core";
import { MetaService } from "../../services/meta/meta.service";
import { StorageEnum } from "../../enums/storage.enum";
import { StorageService } from "../../services/storage/storage.service";

export interface NavLink {
    url: string;
    label: string;
}

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
    private _links: any = [];
    private _isBlurred: boolean = false;

    public constructor(
        private readonly metaService: MetaService,
        private readonly storageService: StorageService,
    ) {
    }

    public ngOnInit(): void {
        this.getNav();
    }

    private getNav(): void {
        this.metaService.header.navbar.children.navLinks.map((navLink: NavLink) => {
            this.links.push({
                url: navLink.url,
                label: navLink.label,
            });
        });
    }

    public get links(): NavLink[] {
        return this._links;
    }

    public get applyButton(): any {
        return this.metaService.header.navbar.children.applyButton;
    }

    public get domainName(): string | null {
        return this.storageService.getItem(StorageEnum.DOMAIN_NAME);
    }

    public get isBlurred(): boolean {
        return this._isBlurred;
    }

    @Input()
    public set isBlurred(isBlurred: boolean) {
        this._isBlurred = isBlurred;
    }
}
