<app-blank
    [hasFooter]="false"
    [hasNavbar]="false"
    [title]="metadata.application.header.content">
    <section class="py-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div>
                        <p [innerHTML]="metadata.application.redirect.content.replace('{{partner_name}}', redirectData.partnerName)"></p>

                        @if (redirectData.logoPath && redirectData.logoPath != "/") {
                            <div class="my-3 d-flex justify-content-center">
                                <img
                                    [src]="'assets/img/partners/' + redirectData.logoPath"
                                    [alt]="redirectData.partnerName"
                                    [height]="80"
                                >
                            </div>
                        }

                        <p [innerHTML]="metadata.application.redirect.secondaryParagraph"></p>

                        <a class="btn btn-secondary mt-3 w-100" (click)="redirectToURL()">
                            <app-count-down
                                [text]="metadata.application.redirect.button"
                                [replace]="'{{time_s}}'"
                                [seconds]="metadata.application.redirect.intervalInSeconds">
                            </app-count-down>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</app-blank>
