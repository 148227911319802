import { Component } from "@angular/core";
import { BaseFormInput } from "../base-form-input";

@Component({
    selector: "app-input-password",
    templateUrl: "./password.component.html",
    styleUrls: ["./password.component.scss"],
})
export class PasswordComponent extends BaseFormInput {
    private _isFieldVisible: boolean = false;

    public constructor() {
        super();
    }

    public toggleVisibility() {
        this.isFieldVisible = !this.isFieldVisible;
    }

    public get isFieldVisible(): boolean {
        return this._isFieldVisible;
    }

    public set isFieldVisible(isVisible: boolean) {
        this._isFieldVisible = isVisible;
    }

    public override get type(): string {
        return this.isFieldVisible ? "text" : "password";
    }

    public get visibilityIcon(): string {
        return this.isFieldVisible ? "<i class=\"bi bi-eye-slash\"></i>" : "<i class=\"bi bi-eye\"></i>";
    }
}
