import { Injectable } from "@angular/core";
import { BehaviorSubject, filter, Observable } from "rxjs";

import { InstanceEnum } from "../../enums/instance.enum";
import { RequestEndpointEnum } from "../../enums/request-endpoint.enum";

@Injectable({
    providedIn: "root",
})
export class LoaderService {
    public instanceLoader$: Observable<{ name: string | null; isLoading: boolean; }>;
    private instanceLoaderSubject: BehaviorSubject<{ name: string | null; isLoading: boolean; }> = new BehaviorSubject<{ name: string | null; isLoading: boolean; }>({ name: null, isLoading: false });

    public constructor() {
        this.instanceLoader$ = this.instanceLoaderSubject.asObservable();
    }

    public setInstanceLoader(value: { name: string | null; isLoading: boolean; }): void {
        this.instanceLoaderSubject.next(value);
    }

    public getInstanceLoader(instanceName: RequestEndpointEnum | InstanceEnum): Observable<{ name: string | null; isLoading: boolean; }> {
        return this.instanceLoader$.pipe(
            filter((res) => {
                return res.name == instanceName;
            }),
        );
    }
}
