<div class="mb-1">
    <small class="text-muted" [innerHTML]="label"></small>
</div>

<div class="d-flex flex-row">
    <div class="form-floating w-33 me-2" [formGroup]="formDatetimeGroup">
        <input
            id="day"
            type="text"
            class="form-control"
            [class.is-valid]="isFormDatetimeControlValid('day')"
            [class.is-invalid]="isFormDatetimeControlInvalid('day')"
            [placeholder]="placeholder"
            formControlName="day"
            [maxlength]="2"
        >
        <label for="day">
            <span>{{ getPlaceholderByIndex(0) }}</span>
        </label>
    </div>

    <div class="form-floating w-33 me-2" [formGroup]="formDatetimeGroup">
        <input
            id="month"
            type="text"
            class="form-control"
            [class.is-valid]="isFormDatetimeControlValid('month')"
            [class.is-invalid]="isFormDatetimeControlInvalid('month')"
            [placeholder]="placeholder"
            formControlName="month"
            [maxlength]="2"
        >
        <label for="month">
            <span>{{ getPlaceholderByIndex(1) }}</span>
        </label>
    </div>

    <div class="form-floating w-33" [formGroup]="formDatetimeGroup">
        <input
            id="year"
            type="text"
            class="form-control"
            [class.is-valid]="isFormDatetimeControlValid('year')"
            [class.is-invalid]="isFormDatetimeControlInvalid('year')"
            [placeholder]="placeholder"
            formControlName="year"
            [maxlength]="4"
        >
        <label for="year">
            <span>{{ getPlaceholderByIndex(2) }}</span>
        </label>
    </div>
</div>
