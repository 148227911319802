import { NgModule } from "@angular/core";
import { RouterLink } from "@angular/router";
import { CommonModule } from "@angular/common";

import { AboutComponent } from "./about.component";
import { ComponentsModule } from "../../components/components.module";
import { ButtonLinkDirective } from "../../directives/button-link.directive";

@NgModule({
    declarations: [
        AboutComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        RouterLink,
        ButtonLinkDirective,
    ],
})
export class AboutModule {
}
