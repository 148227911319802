import { Injectable } from "@angular/core";
import { map, Observable, tap } from "rxjs";

import { MetaService } from "../../../services/meta/meta.service";
import { HttpService } from "../../../services/http/http.service";
import { InstanceEnum } from "../../../enums/instance.enum";
import { RequestEndpointEnum } from "../../../enums/request-endpoint.enum";
import { PolicyPrivacyMetadata, PolicyPrivacyMetadataResponse } from "./policy-privacy.interface";

@Injectable({
    providedIn: "root",
})
export class PolicyPrivacyService {
    public constructor(
        private readonly httpService: HttpService,
        private readonly metaService: MetaService,
    ) {
    }

    public getMetadata(): Observable<PolicyPrivacyMetadata> {
        return this.httpService.get("/content", { section: RequestEndpointEnum.POLICY_PRIVACY })
            .pipe(
                map((res: PolicyPrivacyMetadataResponse) => this.mapper(res)),
                tap((res: PolicyPrivacyMetadata) => this.metaService.setMetadata<PolicyPrivacyMetadata>(res, InstanceEnum.POLICY_PRIVACY)),
            );
    }

    private mapper(response: PolicyPrivacyMetadataResponse): PolicyPrivacyMetadata {
        return {
            main: {
                content: response.privacy_policy.main.content,
                heading: response.privacy_policy.main.heading,
                headline: response.privacy_policy.main.children.headline,
            },
            meta: {
                title: response.privacy_policy.meta.children.title,
                description: response.privacy_policy.meta.children.desc,
            },
        };
    }
}
