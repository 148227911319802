import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

import { StorageEnum } from "../../enums/storage.enum";
import { StorageService } from "../storage/storage.service";

@Injectable({
    providedIn: "root",
})
export class PageDataService {
    public readonly window!: Window;

    public constructor(
        private readonly storageService: StorageService,
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(PLATFORM_ID) private readonly platformId: Object,
    ) {
        if (isPlatformBrowser(this.platformId) && window) {
            this.window = window;
        }
    }

    public getUrlQueryParams(param: string | null = null): string {
        // TODO: Use route func (ActivatedRoute)

        const urlSearchParams = new URLSearchParams(this.window?.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (Object.entries(params).length == 0) {
            return "";
        } else if (!param) {
            return "?" + urlSearchParams;
        } else {
            return params[param] || "";
        }
    }

    public getUrlPath(): string {
        return this.window?.location.pathname;
    }

    public isValidUrl(URL: string): boolean {
        const urlPattern: RegExp = new RegExp(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/); // validate fragment locator
        return urlPattern.test(URL);
    }

    public loadStyleTheme(styleName: string | null): void {
        if (!styleName) {
            return;
        }

        const favicon = this.document.getElementById("favicon") as HTMLLinkElement;
        favicon.href = `assets/img/${styleName}/favicon.png`;

        const head = this.document.getElementsByTagName("head")[0];
        const themeLink = this.document.getElementById("client-theme") as HTMLLinkElement;

        if (themeLink) {
            themeLink.href = `${styleName}.css`;
        } else {
            const style = this.document.createElement("link");

            style.id = "client-theme";
            style.rel = "stylesheet";
            style.href = `${styleName}.css`;

            head.appendChild(style);
        }
    }

    public get domainName(): string | null {
        return this.storageService.getItem(StorageEnum.DOMAIN_NAME);
    }
}
