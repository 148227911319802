import { Injectable } from "@angular/core";
import { finalize, map, Observable, tap } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { DateUtil } from "../../utils/date.util";
import { FormField } from "../application/application.interface";
import { HttpService } from "../../services/http/http.service";
import { FormService } from "../../services/form/form.service";
import { MetaService } from "../../services/meta/meta.service";
import { InstanceEnum } from "../../enums/instance.enum";
import { begin, formValueChanges } from "../../utils/observer.util";
import { RequestEndpointEnum } from "../../enums/request-endpoint.enum";
import { ContactMetadata, ContactMetadataResponse } from "./contact.interface";

@Injectable({
    providedIn: "root",
})
export class ContactService {
    private _fields!: FormField[];
    private _isLoading!: boolean;
    private _metadata!: ContactMetadata;
    private _formReason!: FormGroup;
    private _formRemoval!: FormGroup;
    private _formContact!: FormGroup;
    private _formTimestamp!: FormGroup;
    private _isContactSuccessful: boolean = false;
    private _isRemovalSuccessful: boolean = false;

    public constructor(
        private readonly metaService: MetaService,
        private readonly formService: FormService,
        private readonly formBuilder: FormBuilder,
        private readonly httpService: HttpService,
    ) {
        this.formContact = this.formBuilder.group({});
        this.formRemoval = this.formBuilder.group({});
        this.formTimestamp = this.formBuilder.group({});

        this.formReason = this.formBuilder.group({
            reason: [null, [
                Validators.required,
            ]],
        });

        this.formReason.valueChanges.subscribe({
            next: (value: any) => {
                this.setFormFieldsAccordingToObject(value.reason);
            },
        });

        this.formContact.valueChanges
            .pipe(formValueChanges())
            .subscribe((field: boolean | string | number) => {
                Object.keys(field).map((key: string) => {
                    this.formTimestamp.patchValue({ [key]: DateUtil.getCurrentUnixTimestamp() });
                });
            });
    }

    private setFormFieldsAccordingToObject(reason: string): void {
        if (reason == "generalForm") {
            // TODO: Refactor
            this.fields = this.metadata.generalForm.fields.map((field) => {
                return {
                    type: field.type,
                    name: field.name,
                    label: field.label,
                    validation_error: field.validationError,
                    validation_regexp: field.validationRegex,
                    help_txt: field.helper,
                    required: field.isRequired ? "true" : "false",
                    prepend: field.prepend,
                } as FormField;
            });
            this.formService.mapFormControls(this.formTimestamp, this.fields);
            this.formService.mapFormControlsAndValidators(this.formContact, this.fields);
        } else {
            // TODO: Refactor
            this.fields = this.metadata.deleteForm.fields.map((field) => {
                return {
                    type: "text",
                    name: "email",
                    label: field.label,
                    validation_error: field.validationError,
                    validation_regexp: field.validationRegex,
                } as FormField;
            });
            this.formService.mapFormControlsAndValidators(this.formRemoval, this.fields);
        }
    }

    public submitContact(formContact: FormGroup): void {
        this.httpService.post("/contact/new", this.formService.mapContactFormValues(formContact.value, this.formTimestamp.value))
            .pipe(
                begin(() => {
                    this.isLoading = true;
                }),
                finalize(() => {
                    this.isLoading = false;
                }),
            )
            .subscribe({
                next: () => {
                    this.isContactSuccessful = true;
                },
            });
    }

    public submitRemoval(formRemoval: FormGroup): void {
        this.httpService.post("/user/data/delete", formRemoval.value)
            .pipe(
                begin(() => {
                    this.isLoading = true;
                }),
                finalize(() => {
                    this.isLoading = false;
                }),
            )
            .subscribe({
                next: () => {
                    this.isRemovalSuccessful = true;
                },
            });
    }

    public get formReason(): FormGroup {
        return this._formReason;
    }

    public set formReason(form: FormGroup) {
        this._formReason = form;
    }

    public get formRemoval(): FormGroup {
        return this._formRemoval;
    }

    public set formRemoval(formRemoval: FormGroup) {
        this._formRemoval = formRemoval;
    }

    public get formContact(): FormGroup {
        return this._formContact;
    }

    public set formContact(formContact: FormGroup) {
        this._formContact = formContact;
    }

    public get metadata(): ContactMetadata {
        return this._metadata;
    }

    public set metadata(metadata: ContactMetadata) {
        this._metadata = metadata;
    }

    public get fields(): FormField[] {
        return this._fields;
    }

    public set fields(fields: FormField[]) {
        this._fields = fields;
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }

    public set isLoading(loading: boolean) {
        this._isLoading = loading;
    }

    public get isContactSuccessful(): boolean {
        return this._isContactSuccessful;
    }

    public set isContactSuccessful(isContactSuccessful: boolean) {
        this._isContactSuccessful = isContactSuccessful;
    }

    public get isRemovalSuccessful(): boolean {
        return this._isRemovalSuccessful;
    }

    public set isRemovalSuccessful(isRemovalSuccessful: boolean) {
        this._isRemovalSuccessful = isRemovalSuccessful;
    }

    public get formTimestamp(): FormGroup {
        return this._formTimestamp;
    }

    public set formTimestamp(formTimestamp: FormGroup) {
        this._formTimestamp = formTimestamp;
    }

    public getMetadata(): Observable<ContactMetadata> {
        return this.httpService.get("/content", { section: RequestEndpointEnum.CONTACT })
            .pipe(
                map((res: ContactMetadataResponse) => this.mapper(res)),
                tap((res: ContactMetadata) => this.metaService.setMetadata<ContactMetadata>(res, InstanceEnum.CONTACT)),
            );
    }

    private mapper(response: ContactMetadataResponse): ContactMetadata {
        return {
            main: {
                heading: response.contact.main?.heading,
                details: response.contact.main?.children.details,
                options: response.contact.main?.children.options.map((res) => {
                    return {
                        key: res.key == "general" ? "generalForm" : "deleteForm",
                        label: res.label,
                    };
                }),
                bottomParagraph: response.contact.main?.children.bottom_paragraph,
                optionsParagraph: response.contact.main?.children.options_paragraph,
                optionsPlaceholder: response.contact.main?.children.options_placeholder,
            },
            meta: {
                title: response.contact.meta?.children.title,
                description: response.contact.meta?.children.desc,
            },
            deleteForm: {
                button: response.contact.delete_data_form?.children.button_text,
                content: response.contact.delete_data_form?.content,
                fields: [response.contact.delete_data_form?.children].map((res) => {
                    return {
                        label: res.label,
                        validationError: res.validation_error,
                        validationRegex: res.validation_regexp,
                    };
                }),
                thankYou: {
                    heading: response.contact.delete_data_thankyou.heading,
                    content: response.contact.delete_data_thankyou.content,
                },
            },
            generalForm: {
                button: response.contact.general_form_button?.content,
                fields: response.contact.general_form?.children.map((res) => {
                    return {
                        type: res.type,
                        name: res.name,
                        label: res.label,
                        prepend: res.prepend,
                        isRequired: res.required == "true",
                        helper: res.help_text,
                        validationError: res.validation_error,
                        validationRegex: res.validation_regexp,
                    };
                }),
                thankYou: {
                    heading: response.contact.general_thankyou.heading,
                    content: response.contact.general_thankyou.content,
                },
            },
        };
    }
}
