<div class="form-check mb-3">
    <input
        id="allTop"
        type="checkbox"
        [checked]="allChecked"
        class="form-check-input"
        (change)="toggleCheckbox()"
    >
    <label class="form-check-label" for="allTop">
        {{ metadata.meta.selectAllToggle }}
    </label>
</div>

@for (field of children.data; track index; let index = $index) {
    <div class="ms-3">
        <app-input
            [type]="field.field_type"
            [name]="field.field_name"
            [prepend]="field.prepend"
            [label]="field.label || field.alt_txt"
            [placeholder]="field.placeholder"
            [helperText]="field.help_txt"
            [errorMessage]="field.validation_error"
            [options]="field.options"
            [paragraph]="field.paragraph"
            [headline]="field.headline"
            [children]="field.children"
            [formGroup]="formGroup">
        </app-input>
    </div>
}

@if (children.data.length >= 4) {
    <div class="form-check mb-3">
        <input
            id="allBottom"
            type="checkbox"
            [checked]="allChecked"
            class="form-check-input"
            (change)="toggleCheckbox()"
        >
        <label class="form-check-label" for="allBottom">
            {{ metadata.meta.selectAllToggle }}
        </label>
    </div>
}
