<app-layout [hasFooter]="hasFooter" [hasNavbar]="hasNavbar" [isLoading]="isLoading">
    @if (!isLoading && title) {
        <header [ngClass]="instanceName" class="application-header">
            <figure class="bg-header" [style]="getBackgroundImageCss(image)"></figure>

            <div class="container">
                <h2 class="mb-0">
                    {{ title }}
                </h2>

                @if (header) {
                    <ng-container [ngTemplateOutlet]="header"></ng-container>
                }
            </div>
        </header>
    }

    @if (!isLoading) {
        <ng-container>
            <ng-content></ng-content>
        </ng-container>
    }
</app-layout>
