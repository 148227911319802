@if (service == ServiceEnum.UNNAX) {
    <app-unnax
        [name]="name"
        [url]="url">
    </app-unnax>
}

@if (service == ServiceEnum.INSTANTOR) {
    <app-unnax
        [name]="name"
        [url]="url">
    </app-unnax>
}

@if (hasSkipStep) {
    <div class="text-muted mt-4 small">
        {{ skipStepText }}
    </div>
}
