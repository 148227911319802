import { FormGroup } from "@angular/forms";
import { Component, Input } from "@angular/core";
import { MetaService } from "../../services/meta/meta.service";
import { Metadata } from "../../services/meta/meta.interface";

// TODO: Is it a correct way to implement an abstract component in Angular?
@Component({ template: "" })
export abstract class BaseFormInput {
    private _data!: any;
    private _name!: string;
    private _type!: string;
    private _label!: string;
    private _options!: string[];
    private _prepend!: string;
    private _disabled: boolean = false;
    private _headline!: string;
    private _children!: any;
    private _isLoading: boolean = false;
    private _paragraph!: string;
    private _formGroup!: FormGroup;
    private _helperText!: string;
    private _placeholder!: string;
    private _errorMessage!: string;

    protected constructor(
    ) {
    }

    public patchValue(value: any): void {
        this.formGroup.get(this.name)?.patchValue(value);
    }

    public get isFormControlInvalid(): boolean {
        return this.formGroup.controls[this.name]?.invalid && (this.formGroup.controls[this.name].dirty);
    }

    public get isFormControlValid(): boolean {
        if (this.formGroup.controls[this.name]?.value == "true" || this.formGroup.controls[this.name]?.value == "false") {
            return this.formGroup.controls[this.name]?.valid;
        }

        return this.formGroup.controls[this.name]?.valid && (this.formGroup.controls[this.name].dirty || this.formGroup.controls[this.name].touched);
    }

    public get formGroup(): FormGroup {
        return this._formGroup;
    }

    @Input()
    public set formGroup(formGroup: FormGroup) {
        this._formGroup = formGroup;
    }

    public get type(): string {
        return this._type;
    }

    @Input()
    public set type(type: string) {
        this._type = type;
    }

    public get name(): string {
        return this._name;
    }

    @Input()
    public set name(name: string) {
        this._name = name;
    }

    public get placeholder(): string {
        return this._placeholder;
    }

    @Input()
    public set placeholder(placeholder: string) {
        this._placeholder = placeholder;
    }

    public get helperText(): string {
        return this._helperText;
    }

    @Input()
    public set helperText(helperText: string) {
        this._helperText = helperText;
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    @Input()
    public set errorMessage(errorMessage: string) {
        this._errorMessage = errorMessage;
    }

    public get label(): string {
        return this._label;
    }

    @Input()
    public set label(label: string) {
        this._label = label;
    }

    public get prepend(): string {
        return this._prepend;
    }

    @Input()
    public set prepend(addon: string) {
        this._prepend = addon;
    }

    public get options(): string[] {
        return this._options;
    }

    @Input()
    public set options(options: string[]) {
        this._options = options;
    }

    public get paragraph(): string {
        return this._paragraph;
    }

    @Input()
    public set paragraph(paragraph: string) {
        this._paragraph = paragraph;
    }

    public get headline(): string {
        return this._headline;
    }

    @Input()
    public set headline(headline: string) {
        this._headline = headline;
    }

    public get data(): any {
        return this._data;
    }

    @Input()
    public set data(data: any) {
        this._data = data;
    }

    public get children(): any {
        return this._children;
    }

    @Input()
    public set children(children: any) {
        this._children = children;
    }

    public get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    public set disabled(disabled: boolean) {
        this._disabled = disabled;
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }

    @Input()
    public set isLoading(isLoading: boolean) {
        this._isLoading = isLoading;
    }
}
