import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PolicyTermsComponent } from "./policy-terms.component";
import { ComponentsModule } from "../../../components/components.module";

@NgModule({
    declarations: [
        PolicyTermsComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
    ],
})
export class PolicyTermsModule {
}
