<div
    class="form-check"
    [formGroup]="formGroup"
    [class.is-valid]="isFormControlValid"
    [class.is-invalid]="isFormControlInvalid">
    <input
        [id]="name"
        type="checkbox"
        class="form-check-input"
        (change)="onChange(name)"
        [formControlName]="name"
        [class.is-valid]="isFormControlValid"
        [class.is-invalid]="isFormControlInvalid"
        [checked]="formGroup.get(name)?.value == 'true'"
    >
    <label class="form-check-label" [for]="name">
        <span [innerHTML]="label"></span>
    </label>
</div>
