import { Component } from "@angular/core";
import { BaseFormInput } from "../base-form-input";

@Component({
    selector: "app-input-phone-number",
    templateUrl: "./phone-number.component.html",
    styleUrls: ["./phone-number.component.scss"],
})
export class PhoneNumberComponent extends BaseFormInput {
    public constructor() {
        super();
    }

    public onChange(event: any): void {
        this.patchValue(event.target.value.replace(/[-, ]/g, ""));
    }

    public get mask(): string {
        return " " + (this.children?.input_mask || "000 000 000");
    }
}
