import { filter, map, Observable, of, pairwise } from "rxjs";
import { switchMap, tap } from "rxjs/operators";

export function formValueChanges<T>(): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) => source.pipe(
        pairwise(),
        map(([prev, curr]) => {
            let changes: any = {};

            for (const key in curr) {
                if (prev[key] !== curr[key]) {
                    changes[key] = curr[key];
                }
            }

            return changes;
        }),
        filter((changes: any): boolean => {
            return Object.keys(changes).length !== 0;
        }));
}

export function begin<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) => of({}).pipe(tap(callback), switchMap((o) => source));
}
