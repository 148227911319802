import * as _ from "lodash";
import { Component, OnInit } from "@angular/core";
import { debounce, delayWhen, distinctUntilChanged, finalize, interval, of, timeout, timer } from "rxjs";

import { begin } from "../../../utils/observer.util";
import { FormField } from "../../../instances/application/application.interface";
import { HttpService } from "../../../services/http/http.service";
import { BaseFormInput } from "../base-form-input";
import { ApplicationService } from "../../../instances/application/application.service";

interface MappingAddress {
    fields: {
        [key: string]: string;
    };
    iso_2_country: string;
    output_fields: string[];
}

@Component({
    selector: "app-input",
    templateUrl: "./input.component.html",
    styleUrls: ["./input.component.scss"],
})
export class FormInputComponent extends BaseFormInput implements OnInit {
    private _hasValidator: boolean = true;
    private _isAddressQueryLoading: boolean = false;

    public constructor(
        private readonly httpService: HttpService,
        private readonly applicationService: ApplicationService,
    ) {
        super();
    }

    public ngOnInit(): void {
        // If field contains query data or is a part of query then check its value changes.
        // Debounce time is set to 750ms to prevent to spam API.
        if (this.children && this.children.query_data && this.children.query_endpoint) {
            for (const field of [...this.children.query_data.query_together_with_fields, this.name]) {
                this.formGroup.get(field)?.valueChanges
                    .pipe(
                        debounce(() => this.type == "text" ? timer(750) : of({})),
                        distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
                    )
                    .subscribe((value): void => {
                        if (!value) {
                            return;
                        }

                        const isAllFieldsValid: boolean = this.isAllRequiredFieldsValid();

                        if (
                            (this.children.query_data.query_together_with_fields.length > 0 && isAllFieldsValid) ||
                            (this.children.query_data.query_together_with_fields.length == 0 && this.isFormControlValid)
                        ) {
                            const requestBody = {
                                fields: {
                                    [this.name]: value,
                                    ...this.getQueryWithFields(),
                                },
                                iso_2_country: this.children.query_data.iso_2_country,
                                output_fields: this.children.query_data.output_fields,
                            };

                            // Invalidate form controls if higher level address component has changed/selected.
                            this.invalidateFormControlsByName(this.children.query_data.output_fields);

                            this.httpPostMappingAddress(this.children.query_endpoint, requestBody, this.children.query_timeout_s);
                        }
                    });
            }
        }
    }

    private isAllRequiredFieldsValid(): boolean {
        return this.isFormControlValid && this.children.query_data.query_together_with_fields.every((field: string): boolean => this.formGroup.get(field)?.valid == true);
    }

    private getQueryWithFields(): { [key: string]: string; } {
        let result = {};

        Object.entries(this.formGroup.value)?.map(([key, value]): void => {
            if (this.children.query_data.query_together_with_fields.find((field: string): boolean => field == key)) {
                result = { ...result, [key]: value };
            }
        });

        return result;
    }

    // Fetch data from an endpoint "/mapping/address" if a property "children" is given.
    private httpPostMappingAddress(endpoint: string, body: MappingAddress, timeoutInSeconds: number = 15): void {
        const t0: number = performance.now();

        this.httpService.post(endpoint, body)
            .pipe(
                timeout(timeoutInSeconds * 1000),
                begin(() => {
                    this.applicationService.formApplication.disable();
                    this.isAddressQueryLoading = true;
                }),
                delayWhen(() => {
                    const t1: number = performance.now();
                    const loadingTimeMs: number = t1 - t0;
                    return loadingTimeMs < 800 ? interval(800 - loadingTimeMs) : of(undefined);
                }),
                finalize(() => {
                    this.applicationService.formApplication.enable();
                    this.isAddressQueryLoading = false;
                }),
            )
            .subscribe({
                next: (res): void => {
                    // TODO: Use interface instead of any.
                    res.data.map((data: any): void => {
                        this.applicationService.fields = this.applicationService.fields.map((field: FormField) => {
                            if (field.field_name == data.field_name) {
                                // If options value is less or equal to one then change field type to text.
                                if (data.field_options.length <= 1) {
                                    field.field_type = "text";
                                }

                                // If options value is empty.
                                if (data.field_options.length == 0) {
                                    this.formGroup.get(data.field_name)?.reset();
                                    this.formGroup.get(data.field_name)?.enable();
                                    this.formGroup.get(data.field_name)?.patchValue(null);
                                }

                                // If field is active or options contains one element then disable a field.
                                // Else enable a field.
                                if (data.is_field_active == false) {
                                    this.formGroup.get(data.field_name)?.disable();
                                } else {
                                    this.formGroup.get(data.field_name)?.enable();
                                }

                                // If options contains one element then update field value only.
                                if (data.field_options.length == 1) {
                                    this.formGroup.get(data.field_name)?.patchValue(data.field_options[0]);
                                    this.formGroup.get(data.field_name)?.disable();
                                }

                                // If more array contains more than 1 item then change field type from "text" to "options" and add options to "options".
                                if (data.field_options.length > 1) {
                                    // Change current field type (probably text) to options.
                                    field.field_type = "options";

                                    // If current options is not equivalent to new one then replace data.
                                    if (!_.isEqual(field.options, data.field_options)) {
                                        field.options = data.field_options;
                                    }
                                }
                            }
                            return field;
                        });
                    });
                },
                error: (err): void => {
                    console.error(err);
                },
            });
    }

    public get isAddressQueryLoading(): boolean {
        return this._isAddressQueryLoading;
    }

    public set isAddressQueryLoading(isAddressQueryLoading: boolean) {
        this._isAddressQueryLoading = isAddressQueryLoading;
    }

    public hasValidator$(event: boolean): void {
        this.hasValidator = event;
    }

    public get hasValidator(): boolean {
        return this._hasValidator;
    }

    public set hasValidator(hasValidator: boolean) {
        this._hasValidator = hasValidator;
    }

    private invalidateFormControlsByName(names: string[]): void {
        for (const name of names) {
            this.formGroup.get(name)?.reset();
        }
    }
}
