import { Injectable } from "@angular/core";
import { RequestEndpointEnum } from "../../enums/request-endpoint.enum";
import { map, tap } from "rxjs";
import { HttpService } from "../../services/http/http.service";
import { MetaService } from "../../services/meta/meta.service";
import { AboutMetadata, AboutMetadataResponse } from "./about.interface";
import { InstanceEnum } from "../../enums/instance.enum";

@Injectable({
    providedIn: "root",
})
export class AboutService {
    public constructor(
        private readonly httpService: HttpService,
        private readonly metaService: MetaService,
    ) {
    }

    public getMetadata() {
        return this.httpService.get("/content", { section: RequestEndpointEnum.ABOUT })
            .pipe(
                map((res: AboutMetadataResponse) => this.mapper(res)),
                tap((res: AboutMetadata) => this.metaService.setMetadata<AboutMetadata>(res, InstanceEnum.ABOUT)),
            );
    }

    private mapper(response: AboutMetadataResponse): AboutMetadata {
        return {
            main: {
                content: response.about_us.main.content,
                ctaAfter: response.about_us.main.children.cta_after,
                ctaButton: response.about_us.main.children.cta_button,
                heading: response.about_us.main.heading,
                headline: response.about_us.main.children.headline,
            },
            meta: {
                title: response.about_us.meta.children.title,
                description: response.about_us.meta.children.desc,
            },
        };
    }
}
