import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";

export type Environment = {
    mock: boolean;
    debug: boolean;
    apiUrl: string;
    origin: string;
    production: boolean;
};

@Injectable({
    providedIn: "root",
})
export class EnvironmentService {
    public get env(): Environment {
        return environment;
    }

    public get apiUrl(): string {
        return this.env.apiUrl;
    }

    public get origin(): string {
        return this.env.origin;
    }
}
