import { APP_INITIALIZER, NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { RouteService } from "./services/route/route.service";
import { LandingComponent } from "./instances/landing/landing.component";
import { NotFoundComponent } from "./instances/not-found/not-found.component";

// To manipulate dynamic routes see RouteService in route.service.ts.
const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        component: LandingComponent,
    },
    {
        path: "**",
        pathMatch: "full",
        component: NotFoundComponent,
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: "enabled",
            preloadingStrategy: PreloadAllModules,
        }),
    ],
    exports: [
        RouterModule,
    ],
    providers: [
        {
            deps: [RouteService],
            multi: true,
            provide: APP_INITIALIZER,
            useFactory: (routeService: RouteService) => {
                return () => routeService.fetchRoutes();
            },
        },
    ],
})
export class AppRoutingModule {
}
