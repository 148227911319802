import { Component, Input } from "@angular/core";
import { map, takeWhile, timer } from "rxjs";

@Component({
    selector: "app-count-down",
    templateUrl: "./count-down.component.html",
    styleUrls: ["./count-down.component.scss"],
})
export class CountDownComponent {
    @Input() public text!: string;
    @Input() public replace!: string;
    @Input() public seconds: number = 5;

    public readonly timeRemaining$ = timer(0, 1000).pipe(
        map((n: number): number => (this.seconds - n) * 1000),
        takeWhile((n: number): boolean => n >= 0),
    );
}
