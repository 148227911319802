import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NotFoundComponent } from "./not-found.component";
import { ComponentsModule } from "../../components/components.module";

@NgModule({
    declarations: [
        NotFoundComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
    ],
})
export class NotFoundModule {
}
