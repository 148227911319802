import { Component, OnInit } from "@angular/core";

import { Metadata } from "../../services/meta/meta.interface";
import { MetaService } from "../../services/meta/meta.service";
import { InstanceEnum } from "../../enums/instance.enum";
import { LoaderService } from "../../services/loader/loader.service";

@Component({
    selector: "app-about",
    templateUrl: "./about.component.html",
    styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
    private _isInstanceLoading: boolean = true;

    public constructor(
        private readonly metaService: MetaService,
        private readonly loaderService: LoaderService,
    ) {
    }

    public ngOnInit(): void {
        this.loaderService.getInstanceLoader(this.instanceName).subscribe((res) => {
            this.isInstanceLoading = res.isLoading;
        });
    }

    public get isInstanceLoading(): boolean {
        return this._isInstanceLoading;
    }

    public set isInstanceLoading(isInstanceLoading: boolean) {
        this._isInstanceLoading = isInstanceLoading;
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public get instanceName(): InstanceEnum {
        return InstanceEnum.ABOUT;
    }
}
