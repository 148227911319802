import { Component } from "@angular/core";
import { BaseFormInput } from "../base-form-input";

@Component({
    selector: "app-input-checkbox",
    templateUrl: "./checkbox.component.html",
    styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent extends BaseFormInput {
    public constructor() {
        super();
    }

    public onChange(name: string): void {
        this.patchValue(!!this.formGroup.get(name)?.value);
    }
}
