import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PolicyCookiesComponent } from "./policy-cookies.component";
import { ComponentsModule } from "../../../components/components.module";

@NgModule({
    declarations: [
        PolicyCookiesComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
    ],
})
export class PolicyCookiesModule {
}
