import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "../../components/components.module";
import { RouterModule } from "@angular/router";
import { RedirectComponent } from "./redirect.component";
import { PendingChangesGuard } from "../../guards/pending-changes.guard";

@NgModule({
    declarations: [
        RedirectComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        ComponentsModule,
    ],
    providers: [
        PendingChangesGuard,
    ],
})
export class RedirectModule {
}
