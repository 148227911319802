import { Router } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { catchError, EMPTY, finalize, Observable, of, tap } from "rxjs";

import { begin } from "../../utils/observer.util";
import { Metadata } from "../../services/meta/meta.interface";
import { MetaService } from "../../services/meta/meta.service";
import { HttpService } from "../../services/http/http.service";
import { PageDataService } from "../../services/page-data/page-data.service";

@Injectable({
    providedIn: "root",
})
export class ShortUrlService {
    private _url!: string;
    private _hasError: boolean = false;
    private _isLoading: boolean = true;

    public constructor(
        private readonly router: Router,
        private readonly metaService: MetaService,
        private readonly httpService: HttpService,
        private readonly pageDataService: PageDataService,
        @Inject(PLATFORM_ID) private readonly platformId: Object,
    ) {
    }

    public getUrlByPath(path: string): Observable<{ url: string }> {
        return this.httpService.get("/url/get/" + path).pipe(
            begin((): void => {
                this.isLoading = true;
            }),
            tap(async (res): Promise<void> => {
                this.url = res.url;

                if (!this.isApplicationPath) {
                    await this.redirectToUrl();
                }
            }),
            catchError(async (err) => {
                if (this.isApplicationPath) {
                    this.hasError = true;
                } else {
                    await this.router.navigateByUrl("/404");
                }

                return EMPTY;
            }),
            finalize((): void => {
                if (this.isApplicationPath) {
                    this.isLoading = false;
                }
            }),
        );
    }

    public async redirectToUrl(): Promise<void> {
        if (!this.url) {
            await this.router.navigateByUrl("/");
        } else if (this.pageDataService.isValidUrl(this.url) && this.isBrowser) {
            window.location.replace(this.url);
        } else {
            await this.router.navigateByUrl(this.url + this.pageDataService.getUrlQueryParams());
        }
    }

    public get isApplicationPath(): boolean {
        if (!this.url) {
            return false;
        }
        return this.url.includes(this.metadata.routes.application);
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }

    public set isLoading(isLoading: boolean) {
        this._isLoading = isLoading;
    }

    public get hasError(): boolean {
        return this._hasError;
    }

    public set hasError(hasError: boolean) {
        this._hasError = hasError;
    }

    public get url(): string {
        return this._url;
    }

    public set url(url: string) {
        this._url = url;
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public get isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }
}
