import { Injectable } from "@angular/core";
import { map, Observable, tap } from "rxjs";

import { HttpService } from "src/app/services/http/http.service";
import { MetaService } from "../../../services/meta/meta.service";
import { InstanceEnum } from "../../../enums/instance.enum";
import { RequestEndpointEnum } from "../../../enums/request-endpoint.enum";
import { PolicyCookiesMetadata, PolicyCookiesMetadataResponse } from "./policy-cookies.interface";

@Injectable({
    providedIn: "root",
})
export class PolicyCookiesService {
    public constructor(
        private readonly httpService: HttpService,
        private readonly metaService: MetaService,
    ) {
    }

    public getMetadata(): Observable<PolicyCookiesMetadata> {
        return this.httpService.get("/content", { section: RequestEndpointEnum.POLICY_COOKIES })
            .pipe(
                map((res: PolicyCookiesMetadataResponse) => this.mapper(res)),
                tap((res: PolicyCookiesMetadata) => this.metaService.setMetadata<PolicyCookiesMetadata>(res, InstanceEnum.POLICY_COOKIES)),
            );
    }

    private mapper(response: PolicyCookiesMetadataResponse): PolicyCookiesMetadata {
        return {
            main: {
                content: response.cookie_policy.main.content,
                heading: response.cookie_policy.main.heading,
                headline: response.cookie_policy.main.children.headline,
            },
            meta: {
                title: response.cookie_policy.meta.children.title,
                description: response.cookie_policy.meta.children.desc,
            },
        };
    }
}
