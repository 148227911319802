import { Injectable } from "@angular/core";
import { map, Observable, tap } from "rxjs";
import { PolicyTermsMetadata, PolicyTermsMetadataResponse } from "./policy-terms.interface";
import { RequestEndpointEnum } from "../../../enums/request-endpoint.enum";
import { HttpService } from "../../../services/http/http.service";
import { MetaService } from "../../../services/meta/meta.service";
import { InstanceEnum } from "../../../enums/instance.enum";

@Injectable({
    providedIn: "root",
})
export class PolicyTermsService {
    public constructor(
        private readonly httpService: HttpService,
        private readonly metaService: MetaService,
    ) {
    }

    public getMetadata(): Observable<PolicyTermsMetadata> {
        return this.httpService.get("/content", { section: RequestEndpointEnum.POLICY_TERMS })
            .pipe(
                map((res: PolicyTermsMetadataResponse) => this.mapper(res)),
                tap((res: PolicyTermsMetadata) => this.metaService.setMetadata<PolicyTermsMetadata>(res, InstanceEnum.POLICY_TERMS)),
            );
    }

    private mapper(response: PolicyTermsMetadataResponse): PolicyTermsMetadata {
        return {
            main: {
                content: response.terms_and_conditions.main.content,
                heading: response.terms_and_conditions.main.heading,
                headline: response.terms_and_conditions.main.children.headline,
            },
            meta: {
                title: response.terms_and_conditions.meta.children.title,
                description: response.terms_and_conditions.meta.children.desc,
            },
        };
    }
}
