<div class="form-floating" [formGroup]="formGroup">
    <input
        [id]="name"
        type="number"
        class="form-control"
        [placeholder]="placeholder"
        [formControlName]="name"
        [class.is-valid]="isFormControlValid"
        [class.is-invalid]="isFormControlInvalid"
    >
    <label [for]="name">
        <span [innerHTML]="label"></span>
    </label>
</div>
