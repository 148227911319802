import { Component } from "@angular/core";
import { LoaderService } from "../../../services/loader/loader.service";
import { MetaService } from "../../../services/meta/meta.service";
import { Metadata } from "../../../services/meta/meta.interface";
import { InstanceEnum } from "../../../enums/instance.enum";

@Component({
    selector: "app-policy-terms",
    templateUrl: "./policy-terms.component.html",
    styleUrls: ["./policy-terms.component.scss"],
})
export class PolicyTermsComponent {
    private _isLoadingInstance: boolean = true;

    public constructor(
        private readonly metaService: MetaService,
        private readonly loaderService: LoaderService,
    ) {
    }

    public ngOnInit(): void {
        this.loaderService.getInstanceLoader(this.instanceName).subscribe((res) => {
            this.isLoadingInstance = res.isLoading;
        });
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public get instanceName(): InstanceEnum {
        return InstanceEnum.POLICY_TERMS;
    }

    public get isLoadingInstance(): boolean {
        return this._isLoadingInstance;
    }

    public set isLoadingInstance(isLoadingInstance: boolean) {
        this._isLoadingInstance = isLoadingInstance;
    }
}
