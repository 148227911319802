import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";

import { PolicyTermsMetadata } from "./terms/policy-terms.interface";
import { PolicyTermsService } from "./terms/policy-terms.service";
import { PolicyPrivacyMetadata } from "./privacy/policy-privacy.interface";
import { PolicyPrivacyService } from "./privacy/policy-privacy.service";
import { PolicyCookiesMetadata } from "./cookies/policy-cookies.interface";
import { PolicyCookiesService } from "./cookies/policy-cookies.service";

export const policyTermsResolver: ResolveFn<PolicyTermsMetadata> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const service: PolicyTermsService = inject(PolicyTermsService);
    return service.getMetadata();
};

export const policyPrivacyResolver: ResolveFn<PolicyPrivacyMetadata> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const service: PolicyPrivacyService = inject(PolicyPrivacyService);
    return service.getMetadata();
};

export const policyCookiesResolver: ResolveFn<PolicyCookiesMetadata> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const service: PolicyCookiesService = inject(PolicyCookiesService);
    return service.getMetadata();
};
