<nav class="navbar navbar-expand-lg bg-light" [class.navbar-blur]="isBlurred">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/{{ domainName }}/logo/logo-dark.svg" [alt]="domainName">
        </a>

        <button
            type="button"
            class="navbar-toggler"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarOffcanvas">
            <i class="bi bi-list"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav ms-auto align-items-center">
                @for (link of links; track $index) {
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            [routerLink]="link.url"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }">
                            {{ link.label }}
                        </a>
                    </li>
                }
            </ul>
        </div>
    </div>
</nav>

<div class="offcanvas offcanvas-end" tabindex="-1" id="navbarOffcanvas">
    <div class="offcanvas-header">
        <button
            type="button"
            class="btn btn-sm"
            data-bs-dismiss="offcanvas"
            data-bs-target="#navbarOffcanvas">
            <i class="bi bi-x"></i>
        </button>
    </div>

    <div class="offcanvas-body">
        <div class="d-grid gap-2 col-md-8 col-sm-10 mx-auto">
            @for (link of links; track $index) {
                <button
                    class="btn"
                    type="button"
                    [routerLink]="link.url"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }">
                    {{ link.label }}
                </button>
            }
            <button [routerLink]="applyButton.url" class="btn btn-secondary">
                {{ applyButton.label }}
            </button>
        </div>
    </div>
</div>
