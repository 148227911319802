import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HowComponent } from './how.component';
import { ComponentsModule } from "../../components/components.module";
import { RouterLink } from "@angular/router";
import { ButtonLinkDirective } from "../../directives/button-link.directive";



@NgModule({
  declarations: [
    HowComponent
  ],
    imports: [
        CommonModule,
        ComponentsModule,
        RouterLink,
        ButtonLinkDirective,
    ],
})
export class HowModule { }
