<app-blank
    image="header/header-background-9.jpg"
    [title]="metadata.contact.main.heading"
    [isLoading]="isLoadingInstance"
    [instanceName]="instanceName">
    @if (!isLoadingInstance) {
        <div class="container">
            <section class="py-5">
                <div class="row">
                    <div class="col-md-6 mb-md-0 mb-4">
                        <p class="mb-3">
                            {{ metadata.contact.main.bottomParagraph }}
                        </p>

                        @for (detail of metadata.contact.main.details; track $index) {
                            <h6 class="mb-1">
                                {{ detail.headline }}
                            </h6>
                            <p>
                                {{ detail.paragraph }}
                            </p>
                        }
                    </div>

                    <div class="col-md-6">
                        @if (!isContactSuccessful && !isRemovalSuccessful) {
                            <form [formGroup]="formReason">
                                <div class="form-group mb-3">
                                    <div class="form-floating">
                                        <select
                                            id="reason"
                                            class="form-select"
                                            formControlName="reason"
                                            (change)="onChange($event)"
                                            [class.is-valid]="isFormControlValid"
                                            [class.is-invalid]="isFormControlInvalid">
                                            <option [value]="null" selected>
                                                {{ metadata.contact.main.optionsPlaceholder }}
                                            </option>

                                            @for (option of metadata.contact.main.options; track $index) {
                                                <option [value]="option.key">
                                                    {{ option.label }}
                                                </option>
                                            }
                                        </select>
                                        <label for="reason">
                                            {{ metadata.contact.main.optionsParagraph }}
                                        </label>
                                    </div>
                                </div>
                            </form>
                        }

                        @if (reason == 'generalForm' && !isContactSuccessful) {
                            <form [formGroup]="formContact">
                                @for (field of fields; track index; let index = $index) {
                                    <app-input
                                        [type]="mapFieldType(field).toString()"
                                        [name]="field.name"
                                        [prepend]="field.prepend"
                                        [label]="field.label || field.alt_txt"
                                        [placeholder]="field.placeholder"
                                        [helperText]="field.help_txt"
                                        [errorMessage]="field.validation_error"
                                        [options]="field.options"
                                        [paragraph]="field.paragraph"
                                        [children]="field.children"
                                        [headline]="field.headline"
                                        [formGroup]="formContact">
                                    </app-input>
                                }

                                <div class="text-center">
                                    <button
                                        (click)="submitContact()"
                                        [disabled]="formContact.invalid || isLoading"
                                        class="btn btn-secondary px-5">
                                        @if (isLoading) {
                                            <span
                                                role="status"
                                                class="spinner-border"
                                                style="width: 1rem; height: 1rem;">
                                            </span>
                                        } @else {
                                            {{ metadata.contact.generalForm.button }}
                                        }
                                    </button>
                                </div>
                            </form>
                        }

                        @if (isContactSuccessful) {
                            <div>
                                <h4 [innerHTML]="metadata.contact.generalForm.thankYou.heading"></h4>
                                <p [innerHTML]="metadata.contact.generalForm.thankYou.content"></p>
                            </div>
                        }

                        @if (reason == 'deleteForm' && !isRemovalSuccessful) {
                            <form [formGroup]="formRemoval">
                                @for (field of fields; track index; let index = $index) {
                                    <app-input
                                        [type]="mapFieldType(field).toString()"
                                        [name]="field.name"
                                        [prepend]="field.prepend"
                                        [label]="field.label || field.alt_txt"
                                        [placeholder]="field.placeholder"
                                        [helperText]="field.help_txt"
                                        [errorMessage]="field.validation_error"
                                        [options]="field.options"
                                        [paragraph]="field.paragraph"
                                        [children]="field.children"
                                        [headline]="field.headline"
                                        [formGroup]="formRemoval">
                                    </app-input>
                                }

                                <div class="text-center">
                                    <button
                                        (click)="submitRemoval()"
                                        [disabled]="formRemoval.invalid || isLoading"
                                        class="btn btn-secondary px-5">
                                        @if (isLoading) {
                                            <span
                                                class="spinner-border"
                                                role="status"
                                                style="width: 1rem; height: 1rem;">
                                            </span>
                                        } @else {
                                            {{ metadata.contact.deleteForm.button }}
                                        }
                                    </button>
                                </div>
                            </form>
                        }

                        @if (isRemovalSuccessful) {
                            <div>
                                <h4 [innerHTML]="metadata.contact.deleteForm.thankYou.heading"></h4>
                                <p [innerHTML]="metadata.contact.deleteForm.thankYou.content"></p>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </div>
    }
</app-blank>
