import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, Input } from "@angular/core";

import { Metadata } from "../../../services/meta/meta.interface";
import { MetaService } from "../../../services/meta/meta.service";
import { StorageEnum } from "../../../enums/storage.enum";
import { ApplicationService } from "../../../instances/application/application.service";

@Component({
    selector: "app-modal-warning",
    standalone: true,
    templateUrl: "./modal-warning.component.html",
    styleUrls: ["./modal-warning.component.scss"],
})
export class ModalWarningComponent {
    @Input() public data!: string;

    public constructor(
        private readonly metaService: MetaService,
        private readonly activeModal: NgbActiveModal,
        private readonly applicationService: ApplicationService,
    ) {
    }

    public continue(): void {
        this.activeModal.close({ redirect: false });
    }

    public leave(): void {
        // this.applicationService.fields = null;
        // this.applicationService.applicationSequence = null;
        this.applicationService.isApplicationReturned = null;
        // this.applicationService.applicationProgress = StorageEnum.APPLICATION_PROGRESS_NOT_STARTED_VALUE;
        this.activeModal.close({ redirect: true });
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }
}
