import { FormGroup } from "@angular/forms";
import { Component, OnDestroy, OnInit } from "@angular/core";

import { ContactService } from "./contact.service";
import { FormField } from "../application/application.interface";
import { LoaderService } from "../../services/loader/loader.service";
import { FormService } from "../../services/form/form.service";
import { RequestEndpointEnum } from "../../enums/request-endpoint.enum";
import { MetaService } from "../../services/meta/meta.service";
import { Metadata } from "../../services/meta/meta.interface";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit, OnDestroy {
    private _isLoadingInstance: boolean = true;

    public constructor(
        private readonly metaService: MetaService,
        private readonly formService: FormService,
        private readonly loaderService: LoaderService,
        private readonly contactService: ContactService,
    ) {
        this.contactService.metadata = this.metadata.contact;
    }

    public ngOnInit(): void {
        this.loaderService.getInstanceLoader(this.instanceName).subscribe((res) => {
            this.isLoadingInstance = res.isLoading;
        });
    }

    public ngOnDestroy(): void {
        this.formReason.reset();
        this.formRemoval.reset();
        this.formContact.reset();
        this.isContactSuccessful = false;
        this.isRemovalSuccessful = false;
    }

    public onChange(event: any): void {
        if (event.target.value == "null") {
            this.formReason.patchValue({ reason: null });
        } else {
            this.formReason.patchValue({ reason: event.target.value });
        }
    }

    public mapFieldType(field: FormField): string {
        if (field.name == "phone") {
            return "phone";
        } else if (field.name == "email") {
            return "text";
        } else if (field.type) {
            return field.type;
        } else if (field.field_type) {
            return field.field_type;
        } else {
            return "text";
        }
    }

    public submitContact(): void {
        this.contactService.submitContact(this.formContact);
    }

    public submitRemoval(): void {
        this.contactService.submitRemoval(this.formRemoval);
    }

    public get formReason(): FormGroup {
        return this.contactService.formReason;
    }

    public get formRemoval(): FormGroup {
        return this.contactService.formRemoval;
    }

    public get formContact(): FormGroup {
        return this.contactService.formContact;
    }

    public get fields(): FormField[] {
        return this.contactService.fields;
    }

    public get reason(): string {
        return this.formReason.get("reason")?.value;
    }

    public get isLoading(): boolean {
        return this.contactService.isLoading;
    }

    public set isContactSuccessful(isContactSuccessful: boolean) {
        this.contactService.isContactSuccessful = isContactSuccessful;
    }

    public get isContactSuccessful(): boolean {
        return this.contactService.isContactSuccessful;
    }

    public set isRemovalSuccessful(isRemovalSuccessful: boolean) {
        this.contactService.isRemovalSuccessful = isRemovalSuccessful;
    }

    public get isRemovalSuccessful(): boolean {
        return this.contactService.isRemovalSuccessful;
    }

    public mapFieldOptions(options: string[]): { key: string; label: string; }[] | null {
        return this.formService.mapFieldOptions(options);
    }

    public get isFormControlValid(): boolean {
        return this.formService.isFormControlValid(this.formReason, "reason");
    }

    public get isFormControlInvalid(): boolean {
        return this.formService.isFormControlInvalid(this.formReason, "reason");
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public get instanceName(): RequestEndpointEnum {
        return RequestEndpointEnum.CONTACT;
    }

    public get isLoadingInstance(): boolean {
        return this._isLoadingInstance;
    }

    public set isLoadingInstance(isLoadingInstance: boolean) {
        this._isLoadingInstance = isLoadingInstance;
    }
}
