import { Component, OnInit } from "@angular/core";
import { BaseFormInput } from "../base-form-input";
import { MetaService } from "../../../services/meta/meta.service";
import { FormField } from "../../../instances/application/application.interface";
import { intersection } from "lodash";
import { Metadata } from "../../../services/meta/meta.interface";

@Component({
    selector: "app-input-checkbox-combo",
    templateUrl: "./checkbox-combo.component.html",
    styleUrls: ["./checkbox-combo.component.scss"],
})
export class CheckboxComboComponent extends BaseFormInput implements OnInit {
    private _allChecked: boolean = true;

    public constructor(
        private readonly metaService: MetaService,
    ) {
        super();
    }

    public ngOnInit(): void {
        const checkboxes: string[] = [];

        this.children.data.map((field: FormField) => {
            checkboxes.push(field.field_name);
            if (this.formGroup.get(field.field_name)?.value != true) {
                this.allChecked = false;
            }
        });

        this.formGroup.valueChanges.subscribe((value) => {
            const foundFalse = intersection(Object.keys(value), checkboxes).some((c) => {
                return !value[c];
            });
            this.allChecked = !foundFalse;
        });
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public toggleCheckbox(): void {
        if (!this.allChecked) {
            this.allChecked = true;
            this.children.data.map((field: FormField) => {
                this.formGroup.get(field.field_name)?.patchValue(true);
            });
        } else {
            this.allChecked = false;
            this.children.data.map((field: FormField) => {
                this.formGroup.get(field.field_name)?.patchValue(false);
                this.formGroup.get(field.field_name)?.markAsTouched();
            });
        }
    }

    public get allChecked(): boolean {
        return this._allChecked;
    }

    public set allChecked(allChecked: boolean) {
        this._allChecked = allChecked;
    }
}
