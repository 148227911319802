import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";

import { FaqService } from "./faq.service";
import { FaqMetadata } from "./faq.interface";

export const faqResolver: ResolveFn<FaqMetadata> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const service: FaqService = inject(FaqService);
    return service.getMetadata();
};
