import * as _ from "lodash";
import { Injectable } from "@angular/core";
import { SsrCookieService } from "ngx-cookie-service-ssr";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { delayWhen, finalize, interval, map, Observable, of, Subject, tap } from "rxjs";

import { DateUtil } from "../../utils/date.util";
import { Metadata } from "../../services/meta/meta.interface";
import { CookieEnum } from "../../enums/cookie.enum";
import { HttpService } from "../../services/http/http.service";
import { FormService } from "../../services/form/form.service";
import { StorageEnum } from "../../enums/storage.enum";
import { MetaService } from "../../services/meta/meta.service";
import { InstanceEnum } from "../../enums/instance.enum";
import { StorageService } from "../../services/storage/storage.service";
import { SessionService } from "../../services/session/session.service";
import { PageDataService } from "../../services/page-data/page-data.service";
import { formValueChanges } from "../../utils/observer.util";
import { RequestEndpointEnum } from "../../enums/request-endpoint.enum";
import { ApplicationMetadata, ApplicationMetadataResponse, ApplicationRedirectData, ApplicationResponse, FormField } from "./application.interface";

@Injectable({
    providedIn: "root",
})
export class ApplicationService {
    private _isLoading!: boolean;
    private _formKickOff!: FormGroup;
    private _redirectData!: ApplicationRedirectData;
    private _defaultPeriod: number = 61;
    private _requestAmount!: number | null;
    private _requestPeriod!: number | null;
    private _formTimestamp!: FormGroup;
    private _formGetAnOffer!: FormGroup;
    private _formApplication!: FormGroup;
    private _hasPendingApplicationWarning: boolean = false;
    private _isApplicationRequestLoading!: boolean;
    private _application$: Subject<any> = new Subject<any>();

    public constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly formService: FormService,
        private readonly formBuilder: FormBuilder,
        private readonly httpService: HttpService,
        private readonly metaService: MetaService,
        private readonly cookieService: SsrCookieService,
        private readonly storageService: StorageService,
        private readonly sessionService: SessionService,
        private readonly pageDataService: PageDataService,
    ) {
        // If application is in progress and is not returned application.
        // If else then clear the funnel and start over.
        if (this.isApplicationInProgress && !this.isApplicationReturned && (this.applicationExpiresAt && Date.now() < this.applicationExpiresAt.getTime())) {
            this.hasPendingApplicationWarning = true;
        } else {
            this.fields = null;
            this.applicationSequence = null;
            this.applicationExpiresAt = null;
            this.applicationProgress = StorageEnum.APPLICATION_PROGRESS_NOT_STARTED_VALUE;
        }

        // Init a form for the application.
        this.formApplication = this.formBuilder.group({});

        this.application$.subscribe(() => {
            this.formApplication.valueChanges
                .pipe(formValueChanges())
                .subscribe((field: boolean | string | number) => {
                    Object.keys(field).map((key: string) => {
                        this.formTimestamp.patchValue({ [key]: DateUtil.getCurrentUnixTimestamp() });
                    });
                });
        });

        this.formTimestamp = this.formBuilder.group({
            amount: [null],
            period: [null],
        });

        this.formKickOff = this.formBuilder.group({
            amount: [this.amount || this.defaultAmount, [
                Validators.required,
                Validators.min(this.minAmount),
                Validators.max(this.maxAmount),
            ]],
            period: [this.period || this.defaultPeriod, [
                Validators.required,
            ]],
        });

        this.formKickOff.valueChanges
            .pipe(formValueChanges())
            .subscribe((field: boolean | string | number) => {
                Object.keys(field).map((key: string) => {
                    this.formTimestamp.patchValue({ [key]: DateUtil.getCurrentUnixTimestamp() });

                    // Save timestamp when period selected.
                    if (key == "period") {
                        this.applicationPeriodSelectedAt = DateUtil.getCurrentUnixTimestamp();
                    }

                    // Save timestamp when amount selected.
                    if (key == "amount") {
                        this.applicationAmountSelectedAt = DateUtil.getCurrentUnixTimestamp();
                    }
                });
            });

        // TODO: Use an observer?
        if (!this.isApplicationInProgress) {
            this.formKickOff.valueChanges.subscribe((value) => {
                this.period = value.period;
                this.amount = value.amount;
            });
        }

        this.formGetAnOffer = this.formBuilder.group({
            amount: [this.defaultAmount, [Validators.required]],
        });
    }

    public resetFormKickOff(): void {
        this.formKickOff.patchValue({
            amount: this.defaultAmount,
            period: this.defaultPeriod,
        });
    }

    public resetFormApplication(): void {
        this.formApplication.reset();
        this.formApplication = this.formBuilder.group({});
        this.application$.next(true);
    }

    public resetRequestedApplication(): void {
        this.requestAmount = null;
        this.requestPeriod = null;
    }

    public updateApplication(formApplication: FormGroup, emptyRequest: boolean = false): void {
        this.isLoading = true;
        this.application$.next(true);

        if (formApplication.value["sms_verifications_target_14"]) {
            this.httpPostVerifyPhone(this.formApplication);
        } else {
            this.httpPatchApplication(formApplication, emptyRequest);
        }
    }

    public returnApplication(): void {
        this.isLoading = true;
        this.application$.next(true);

        this.httpService.patch("/application/update", {
                srch: this.pageDataService.getUrlQueryParams(),
                vid: this.sessionId,
            })
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                }),
            )
            .subscribe({
                next: async (res: ApplicationResponse) => {
                    this.applicationUpdatedAt = DateUtil.getCurrentUnixTimestamp();
                    this.mapFieldsForForm(res.seq, res.fields);

                    if (this.pageDataService.getUrlQueryParams().includes("utm_seq")) {
                        this.isApplicationReturned = true;
                    }
                },
                error: (err): void => {
                    console.error(err);
                    this.storageService.setItem(StorageEnum.APPLICATION_PROGRESS, StorageEnum.APPLICATION_PROGRESS_NOT_STARTED_VALUE);

                    if (this.pageDataService.getUrlQueryParams().includes("utm_seq")) {
                        this.isApplicationReturned = false;
                    }
                },
            });
    }

    private httpPatchApplication(formApplication: FormGroup, emptyRequest: boolean = true): void {
        const t0: number = performance.now();

        this.httpService.patch("/application/update", {
                t: this.applicationUpdatedAt,
                seq: this.applicationSequence,
                field_val: (!emptyRequest && this.formService.mapApplicationFormValues(formApplication.getRawValue(), this.formTimestamp.value) || {}),
            })
            .pipe(
                delayWhen(() => {
                    const t1: number = performance.now();
                    const loadingTimeMs: number = t1 - t0;
                    return loadingTimeMs < 800 ? interval(800 - loadingTimeMs) : of(undefined);
                }),
                finalize(() => {
                    this.isLoading = false;
                    this.hasPendingApplicationWarning = false;
                }),
            )
            .subscribe({
                next: async (res: ApplicationResponse) => {
                    this.applicationUpdatedAt = DateUtil.getCurrentUnixTimestamp();

                    if (res.fields && res.seq) {
                        this.mapFieldsForForm(res.seq, res.fields);
                    } else {
                        this.setRedirectData(res);
                        await this.router.navigate(["/redirect"]);
                    }
                },
                error: (err) => {
                    console.error(err);
                    this.storageService.setItem(StorageEnum.APPLICATION_PROGRESS, StorageEnum.APPLICATION_PROGRESS_NOT_STARTED_VALUE);
                },
            });
    }

    public httpPostNewApplication(amount: number, period: number): void {
        const t0: number = performance.now();

        this.resetFormApplication();

        this.amount = amount;
        this.period = period;

        this.httpService.post("/application/new", {
                vid: this.sessionId,
                utm_params: this.queryParams,
                duration_days: period,
                amount_requested: amount,
                t: [
                    this.pageLoadedAt,
                    this.applicationAmountSelectedAt,
                    this.applicationPeriodSelectedAt,
                ],
            })
            .pipe(
                delayWhen(() => {
                    const t1: number = performance.now();
                    const loadingTimeMs: number = t1 - t0;
                    return loadingTimeMs < 800 ? interval(800 - loadingTimeMs) : of(undefined);
                }),
                finalize(() => {
                    this.isApplicationRequestLoading = true;
                    this.isLoading = false;
                    this.hasPendingApplicationWarning = false;
                }),
            )
            .subscribe({
                next: async (res: ApplicationResponse): Promise<void> => {
                    this.mapFieldsForForm(res.seq, res.fields);
                    this.storageService.setItem(StorageEnum.APPLICATION_PROGRESS, StorageEnum.APPLICATION_PROGRESS_STARTED_VALUE);
                    this.storageService.setItem(StorageEnum.IGNORE_PENDING_CHANGES, false);
                    this.storageService.setItem(StorageEnum.APPLICATION_AMOUNT, amount);
                    this.storageService.setItem(StorageEnum.APPLICATION_PERIOD, period);
                    this.applicationExpiresAt = DateUtil.setDaysToDate(new Date(), parseInt(this.metadata.footer.cookie_modal.children.ageD)).toISOString();
                    await this.router.navigate(["/solicita"]);
                },
                error: (err): void => {
                    console.error(err);
                },
            });
    }

    private httpPostVerifyPhone(formApplication: FormGroup): void {
        this.isLoading = true;

        this.httpService.post("/application/verify/xphone", {
            t: this.applicationUpdatedAt,
            seq: this.applicationSequence,
            field_val: this.formService.mapApplicationFormValues({
                sms_verifications_target_14: formApplication.value.sms_verifications_target_14,
            }, this.formTimestamp.value),
        }).subscribe({
            next: (): void => {
                this.formApplication.patchValue({
                    sms_verifications_target_14: "passed",
                });
                this.httpPatchApplication(formApplication);
            },
            error: (err): void => {
                console.error(err);
                this.isLoading = false;
            },
        });
    }

    public mapFieldsForForm(sequence: string, fields: FormField[]): void {
        // Set fields.
        this.fields = fields;

        // Set sequence.
        this.applicationSequence = sequence;

        // Remove all controls from form.
        this.formService.removeAllFormControls(this.formApplication);

        // Add fields to "formTimestamp".
        // TODO: Maybe can be merged with "formApplication"?
        this.formService.mapFormControls(this.formTimestamp, fields);

        // Add fields to "formApplication".
        this.formService.mapFormControlsAndValidators(this.formApplication, fields);

        // If debugging is enabled for the environment.
        // if (this.environmentService.env.debug) {
        //     this.fakerService.setDefaultValues(this.formApplication, fields);
        // }
    }

    public async submitRequestApplication(): Promise<void> {
        this.application$.next(true);

        this.requestAmount = this.formKickOff.value.amount;
        this.requestPeriod = this.formKickOff.value.period;

        // If progress value is "1" then show warning.
        // Or else register a new application.
        if (this.applicationProgress == StorageEnum.APPLICATION_PROGRESS_STARTED_VALUE) {
            this.hasPendingApplicationWarning = true;
            await this.router.navigate(["/solicita"]);
        } else {
            if (!this.isApplicationReturned) {
                this.httpPostNewApplication(this.formKickOff.value.amount, this.formKickOff.value.period);
            } else {
                await this.router.navigate(["/solicita"]);
            }
        }
    }

    public get applicationExpiresAt(): Date | null {
        const expiresAt = this.storageService.getItem(StorageEnum.APPLICATION_EXPIRES_AT);

        if (expiresAt) {
            return new Date(expiresAt);
        } else {
            return null;
        }
    }

    public set applicationExpiresAt(applicationExpiresAt: string | null) {
        if (applicationExpiresAt) {
            this.storageService.setItem(StorageEnum.APPLICATION_EXPIRES_AT, applicationExpiresAt);
        } else {
            this.storageService.removeItem(StorageEnum.APPLICATION_EXPIRES_AT);
        }
    }

    public get hasTosCookie(): boolean {
        return this.cookieService.check(CookieEnum.TOS_ID);
    }

    public get isApplicationInProgress(): boolean {
        return this.storageService.getItem(StorageEnum.APPLICATION_PROGRESS) == StorageEnum.APPLICATION_PROGRESS_STARTED_VALUE;
    }

    public get formApplication(): FormGroup {
        return this._formApplication;
    }

    public set formApplication(form: FormGroup) {
        this._formApplication = form;
    }

    public get defaultAmount(): number {
        return this.metadata.applicationKickoff.amount_options.children.slider_default_position || 300;
    }

    public get step(): number | undefined {
        return this.metadata.applicationKickoff.amount_options.children.step;
    }

    public get minAmount(): number {
        return this.metadata.applicationKickoff.amount_options.children.min;
    }

    public get maxAmount(): number {
        return this.metadata.applicationKickoff.amount_options.children.max;
    }

    public get defaultPeriod(): number {
        return this._defaultPeriod;
    }

    public set defaultPeriod(defaultPeriod: number) {
        this._defaultPeriod = defaultPeriod;
    }

    public get applicationSequence(): string | null {
        return this.storageService.getItem(StorageEnum.APPLICATION_SEQUENCE);
    }

    public set applicationSequence(sequence: string | null) {
        if (sequence) {
            this.storageService.setItem(StorageEnum.APPLICATION_SEQUENCE, sequence);
        } else {
            this.storageService.removeItem(StorageEnum.APPLICATION_SEQUENCE);
        }
    }

    public get applicationUpdatedAt(): number | null {
        return Number(this.storageService.getItem(StorageEnum.APPLICATION_UPDATED_AT)) || DateUtil.getCurrentUnixTimestamp();
    }

    public set applicationUpdatedAt(updatedAt: number | null) {
        if (updatedAt) {
            this.storageService.setItem(StorageEnum.APPLICATION_UPDATED_AT, updatedAt);
        }
    }

    public get applicationPeriodSelectedAt(): number | null {
        return Number(this.storageService.getItem(StorageEnum.APPLICATION_PERIOD_SELECTED_AT));
    }

    public set applicationPeriodSelectedAt(selectedAt: number | null) {
        if (selectedAt) {
            this.storageService.setItem(StorageEnum.APPLICATION_PERIOD_SELECTED_AT, selectedAt);
        }
    }

    public get applicationAmountSelectedAt(): number | null {
        return Number(this.storageService.getItem(StorageEnum.APPLICATION_AMOUNT_SELECTED_AT));
    }

    public set applicationAmountSelectedAt(selectedAt: number | null) {
        if (selectedAt) {
            this.storageService.setItem(StorageEnum.APPLICATION_AMOUNT_SELECTED_AT, selectedAt);
        }
    }

    public get pageLoadedAt(): number | null {
        return Number(this.storageService.getItem(StorageEnum.PAGE_LOADED_AT));
    }

    public get fields(): FormField[] {
        return JSON.parse(this.storageService.getItem(StorageEnum.APPLICATION_FIELDS) || "[]");
    }

    public set fields(fields: FormField[] | null) {
        if (fields) {
            fields = this.mapCheckboxFields(fields);
            this.storageService.setItem(StorageEnum.APPLICATION_FIELDS, JSON.stringify(fields));
        } else {
            this.storageService.removeItem(StorageEnum.APPLICATION_FIELDS);
        }
    }

    // TODO: Refactoring needed.
    // Hard coded due to lack of API.
    private mapCheckboxFields(fields: FormField[]): FormField[] {
        const mappedFields: FormField[] = [];
        const checkboxFields: FormField[] = [];

        fields.map((field: FormField): void => {
            if (field.field_type == "checkbox") {
                checkboxFields.push(field);
            } else {
                mappedFields.push(field);
            }
        });

        if (checkboxFields.length > 1) {
            // TODO: Refactor
            mappedFields.push({
                type: "checkbox_combo",
                checked: "false",
                field_name: "checkbox_combo",
                field_type: "checkbox_combo",
                options: [],
                children: {
                    data: checkboxFields,
                },
            } as unknown as FormField);
        } else {
            mappedFields.push(...checkboxFields);
        }

        return mappedFields;
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }

    public set isLoading(loading: boolean) {
        this._isLoading = loading;
    }

    public get formTimestamp(): FormGroup {
        return this._formTimestamp;
    }

    public get isApplicationRequestLoading(): boolean {
        return this._isApplicationRequestLoading;
    }

    public set isApplicationRequestLoading(isApplicationRequestLoading: boolean) {
        this._isApplicationRequestLoading = isApplicationRequestLoading;
    }

    public set formTimestamp(formTimestamp: FormGroup) {
        this._formTimestamp = formTimestamp;
    }

    public get formKickOff(): FormGroup {
        return this._formKickOff;
    }

    public set formKickOff(formKickoff: FormGroup) {
        this._formKickOff = formKickoff;
    }

    public get amount(): number {
        return Number(this.storageService.getItem(StorageEnum.APPLICATION_AMOUNT));
    }

    public set amount(amount: number) {
        this.storageService.setItem(StorageEnum.APPLICATION_AMOUNT, amount);
    }

    public get period(): number {
        return Number(this.storageService.getItem(StorageEnum.APPLICATION_PERIOD));
    }

    public set period(period: number) {
        this.storageService.setItem(StorageEnum.APPLICATION_PERIOD, period);
    }

    public get applicationProgress(): string | null {
        return this.storageService.getItem(StorageEnum.APPLICATION_PROGRESS);
    }

    public set applicationProgress(applicationValue: StorageEnum.APPLICATION_PROGRESS_NOT_STARTED_VALUE | StorageEnum.APPLICATION_PROGRESS_STARTED_VALUE) {
        this.storageService.setItem(StorageEnum.APPLICATION_PROGRESS, applicationValue);
    }

    public get redirectData(): ApplicationRedirectData {
        return this._redirectData;
    }

    public set redirectData(redirectData: ApplicationRedirectData) {
        this._redirectData = redirectData;
    }

    public get sessionId(): string {
        return this.sessionService.sessionId;
    }

    private setRedirectData(response: ApplicationResponse): void {
        this.storageService.setItem(StorageEnum.APPLICATION_PROGRESS, 0);
        this.storageService.setItem(StorageEnum.IGNORE_PENDING_CHANGES, true);
        this.redirectData = {
            logoPath: response.partner_logo_path,
            applicationId: response.id,
            redirectUrl: response.redirect_url,
            partnerName: response.partner_name,
        };
    }

    public get formGetAnOffer(): FormGroup {
        return this._formGetAnOffer;
    }

    public set formGetAnOffer(formGetAnOffer: FormGroup) {
        this._formGetAnOffer = formGetAnOffer;
    }

    public get requestAmount(): number | null {
        return this._requestAmount;
    }

    public set requestAmount(requestAmount: number | null) {
        this._requestAmount = requestAmount;
    }

    public get requestPeriod(): number | null {
        return this._requestPeriod;
    }

    public set requestPeriod(requestPeriod: number | null) {
        this._requestPeriod = requestPeriod;
    }

    public get hasPendingApplicationWarning(): boolean {
        return this._hasPendingApplicationWarning;
    }

    public set hasPendingApplicationWarning(hasPendingApplicationWarning: boolean) {
        this._hasPendingApplicationWarning = hasPendingApplicationWarning;
    }

    public get isApplicationReturned(): boolean | null {
        if (!this.storageService.getItem(StorageEnum.IS_APPLICATION_RETURNED)) {
            return null;
        }
        return this.storageService.getItem(StorageEnum.IS_APPLICATION_RETURNED) == "true";
    }

    public set isApplicationReturned(isApplicationReturned: boolean | null) {
        if (isApplicationReturned == null) {
            this.storageService.removeItem(StorageEnum.IS_APPLICATION_RETURNED);
        } else {
            this.storageService.setItem(StorageEnum.IS_APPLICATION_RETURNED, isApplicationReturned);
        }
    }

    public get applicationInProgressAndRequestedAnother(): boolean {
        return this.applicationProgress == StorageEnum.APPLICATION_PROGRESS_STARTED_VALUE && !!this.requestPeriod && !!this.requestAmount;
    }

    public get isIframeStep(): boolean {
        return !!_.find(this.fields, field => field.field_type == "iframe");
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public get queryParams(): string {
        return this.pageDataService.getUrlQueryParams();
    }

    public get application$(): Subject<any> {
        return this._application$;
    }

    public get isNotStartedApplication(): boolean {
        return this.applicationProgress == StorageEnum.APPLICATION_PROGRESS_NOT_STARTED_VALUE;
    }

    public get isStartedApplication(): boolean {
        return this.applicationProgress == StorageEnum.APPLICATION_PROGRESS_STARTED_VALUE;
    }

    public getMetadata(): Observable<ApplicationMetadata> {
        return this.httpService.get("/content", { section: RequestEndpointEnum.APPLICATION_RESUME })
            .pipe(
                map((res: ApplicationMetadataResponse) => this.mapper(res)),
                tap((res: ApplicationMetadata) => this.metaService.setMetadata<ApplicationMetadata>(res, InstanceEnum.APPLICATION)),
            );
    }

    private mapper(response: ApplicationMetadataResponse): ApplicationMetadata {
        return {
            dropdown: {
                notFound: response.application_resume.dropdown_not_found.children.message,
            },
            header: {
                items: response.application_resume.header_tab.children,
                content: response.application_resume.hero_text.content,
            },
            main: {
                ctaButton: response.application_resume.cta_button.content,
            },
            redirect: {
                button: response.application_resume.redirect.children.button_text,
                content: response.application_resume.redirect.content,
                intervalInSeconds: response.application_resume.redirect.children.time_s,
                secondaryParagraph: response.application_resume.redirect.children.secondary_paragraph,
            },
            inProgressModal: {
                heading: response.application_resume.application_in_progress_popup.heading,
                leaveButton: response.application_resume.application_in_progress_popup.children.leave,
                continueButton: response.application_resume.application_in_progress_popup.children.continue,
            },
        };
    }
}
