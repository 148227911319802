import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class HttpService {
    public constructor(
        private readonly http: HttpClient,
    ) {
    }

    public get(path: string, params: any = {}): Observable<any> {
        return this.http.get(path, { params });
    }

    public put(path: string, data: object = {}): Observable<any> {
        return this.http.put(path, JSON.stringify(data));
    }

    public post(path: string, data: object = {}): Observable<any> {
        return this.http.post(path, JSON.stringify(data));
    }

    public patch(path: string, data: object = {}): Observable<any> {
        return this.http.patch(path, JSON.stringify(data));
    }

    public delete(path: string): Observable<any> {
        return this.http.delete(path);
    }
}
