import { Component, Directive, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from "@angular/core";
import { BaseFormInput } from "../base-form-input";
import { MetaService } from "../../../services/meta/meta.service";
import { Metadata } from "../../../services/meta/meta.interface";

@Directive({
    selector: "[outsideClick]",
    standalone: true,
})
class OutsideClickDirective {
    @Output()
    outsideClick: EventEmitter<MouseEvent> = new EventEmitter();

    @HostListener("click", ["$event"])
    onClick(event: MouseEvent): void {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.outsideClick.emit(event);
        }
    }

    constructor(private elementRef: ElementRef) {
    }
}

@Component({
    selector: "app-select-dropdown",
    templateUrl: "./select-dropdown.component.html",
    styleUrl: "./select-dropdown.component.scss",
})
export class SelectDropdownComponent extends BaseFormInput implements OnInit {
    private _optionsReplication: string[] = [];
    @ViewChild("dropdown") public dropdown!: ElementRef;

    public isOpen: boolean = false;

    public setIsOpen(isOpen: boolean) {
        this.isOpen = isOpen;
    }

    public constructor(
        private readonly metaService: MetaService,
    ) {
        super();
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public ngOnInit(): void {
        this.optionsReplication = this.options;
    }

    public onSelect(option: string): void {
        if (option == "null") {
            this.patchValue(null);
        } else {
            this.patchValue(option);
        }
    }

    public get optionsReplication(): string[] {
        return this._optionsReplication;
    }

    public set optionsReplication(optionsReplication: string[]) {
        this._optionsReplication = optionsReplication;
    }

    public onChangeDropdownSearchInput($event: Event): void {
        this.optionsReplication = this.searchFaqByQuestion(($event.target as HTMLInputElement).value, this.options);
    }

    public searchFaqByQuestion(query: string, options: string[]): string[] {
        return options.filter((option: string) =>
            option
                .normalize("NFD")
                .replace(/\p{Diacritic}/gu, "")
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1);
    }
}
