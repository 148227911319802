<div class="form-floating" [formGroup]="formGroup">
    <textarea
        [id]="name"
        [rows]="3"
        style="height: 100px;"
        class="form-control"
        [class.is-invalid]="isFormControlInvalid"
        [class.is-valid]="isFormControlValid"
        [placeholder]="placeholder"
        [formControlName]="name">
    </textarea>
    @if (label) {
        <label [for]="name" class="form-label">
            {{ label }}
        </label>
    }
</div>
