import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Component, Input } from "@angular/core";

@Component({ template: "" })
export class Iframe {
    private _url!: string;
    private _name!: string;

    public constructor(
        protected readonly sanitizer: DomSanitizer,
    ) {
    }

    public get url(): string {
        return this._url;
    }

    @Input()
    public set url(url: string) {
        this._url = url;
    }

    public get name(): string {
        return this._name;
    }

    @Input()
    public set name(name: string) {
        this._name = name;
    }

    public sanitizeUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
