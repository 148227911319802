<app-blank
    image="header/header-background-5.jpg"
    [title]="metadata.application.header.content"
    [isLoading]="isLoadingInstance"
    [instanceName]="instanceName">
    @if (!isLoadingInstance) {
        @if (!isApplicationReturned) {
            <header class="application-header">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-6">
                            <div class="row application-options justify-content-center">
                                <div class="col-auto d-flex align-items-center flex-column">
                                <span>
                                    {{ metadata.applicationKickoff.amount_options.children.label }}
                                </span>
                                    <h5 class="currency-unit">
                                    <span class="value">
                                        {{ separateWithCommas(amount) }}
                                    </span>
                                        <span class="unit">
                                        {{ metadata.applicationKickoff.amount_options.children.currency_unit }}
                                    </span>
                                    </h5>
                                </div>

                                <div class="col-auto d-flex align-items-center flex-column">
                                <span>
                                    {{ metadata.applicationKickoff.duration_options.children.label }}
                                </span>
                                    <h5>
                                        {{ period }} {{ metadata.applicationKickoff.duration_options.children.unit }}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        }

        <section class="py-5" #scrollToElementRef>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-5 col-lg-6 col-md-8">
                        @if (hasPendingApplicationWarning && !isApplicationReturned) {
                            <h4>
                                {{ metadata.landing.resumeStartedApplication.heading }}
                            </h4>
                            <div class="d-grid gap-2">
                                <button
                                    #buttonLink1
                                    buttonLink
                                    class="btn btn-secondary"
                                    [disabled]="buttonLink2.disabled"
                                    [buttonLinkDelay]="650"
                                    (buttonLinkCallback)="continueApplication()">
                                    {{ metadata.landing.resumeStartedApplication.continue }}
                                </button>

                                <button
                                    #buttonLink2
                                    buttonLink
                                    class="btn btn-outline-dark"
                                    [disabled]="buttonLink1.disabled"
                                    [buttonLinkDelay]="650"
                                    (buttonLinkCallback)="requestNewApplication()">
                                    {{ metadata.landing.resumeStartedApplication.startNew }}
                                </button>
                            </div>
                        }

                        @if (isNotStartedApplication && !hasPendingApplicationWarning && !isApplicationReturned) {
                            <div class="d-flex justify-content-center flex-column">
                                <form [formGroup]="formKickOff">
                                    <div class="form-group">
                                        <label
                                            for="amount"
                                            class="form-label w-100 d-flex justify-content-between align-items-center">
                                            <span>
                                                {{ metadata.applicationKickoff.amount_options.children.label }}
                                            </span>
                                            <span class="currency-unit text-secondary fs-5 fw-semibold">
                                                <span class="value">
                                                     {{ separateWithCommas(formKickOff.value.amount) }}
                                                </span>
                                                <span class="unit">
                                                    {{ metadata.applicationKickoff.amount_options.children.currency_unit }}
                                                </span>
                                            </span>
                                        </label>
                                        <input
                                            type="range"
                                            class="form-range form-range-gray"
                                            id="amount"
                                            [value]="amount"
                                            [min]="minAmount"
                                            [max]="maxAmount"
                                            [step]="step"
                                            formControlName="amount"
                                        >
                                    </div>

                                    <div class="form-group">
                                        <label class="form-label">
                                            {{ metadata.applicationKickoff.duration_options.children.label }}
                                        </label>

                                        <div class="d-flex flex-row w-100 gap-2" role="group">
                                            <input
                                                type="radio"
                                                class="btn-check"
                                                name="period"
                                                id="period-1"
                                                [value]="61"
                                                autocomplete="off"
                                                [checked]="true"
                                                formControlName="period"
                                            >
                                            <label
                                                class="btn btn-outline-dark w-100"
                                                for="period-1">
                                                61 {{ metadata.applicationKickoff.duration_options.children.unit }}
                                            </label>

                                            <input
                                                type="radio"
                                                class="btn-check"
                                                name="period"
                                                [value]="90"
                                                id="period-2"
                                                autocomplete="off"
                                                formControlName="period"
                                            >
                                            <label
                                                class="btn btn-outline-dark w-100"
                                                for="period-2">
                                                90 {{ metadata.applicationKickoff.duration_options.children.unit }}
                                            </label>
                                        </div>
                                    </div>
                                </form>

                                <button
                                    buttonLink
                                    class="btn btn-secondary mt-2"
                                    (buttonLinkCallback)="submitRequestApplication()">
                                    {{ metadata.applicationKickoff.cta_button.content }}
                                </button>

                                <p class="text-center mt-2">
                                    {{ metadata.applicationKickoff.consent_privacy.children?.without_obligation }}
                                </p>
                            </div>
                        }

                        @if ((isStartedApplication && !redirectData && !hasPendingApplicationWarning) || isApplicationReturned) {
                            <form [formGroup]="formApplication">
                                @for (field of fields; track field.field_name) {
                                    @if (field.field_type != "iframe") {
                                        <app-input
                                            [type]="field.field_type"
                                            [name]="field.field_name"
                                            [prepend]="field.prepend"
                                            [label]="field.label || field.alt_txt"
                                            [placeholder]="field.placeholder"
                                            [helperText]="field.help_txt"
                                            [errorMessage]="field.validation_error"
                                            [options]="field.options"
                                            [paragraph]="field.paragraph"
                                            [headline]="field.headline"
                                            [children]="field.children"
                                            [data]="getData(field)"
                                            [formGroup]="formApplication">
                                        </app-input>
                                    }

                                    @if (field.field_type == "iframe") {
                                        <app-iframe
                                            [url]="field.children.iframe_url || field.children.iframe_base_url"
                                            [service]="field.children.service"
                                            [loadTimeout]="field.children.iframe_load_timeout_s"
                                            [bankLoadTimeout]="field.children.iframe_banks_load_timeout_s"
                                            [failedLoginMaxTries]="field.children.iframe_failed_logins_max"
                                            [loaderMessage]="field.children.iframe_loader_message"
                                            [delayBeforeNextStep]="field.children.iframe_sleep_before_next_step"
                                            [skipStepText]="field.children.skip_step_text"
                                            [skipStepDelayBeforeDisplay]="field.children.skip_step_show_after_s"
                                        >
                                        </app-iframe>
                                    }
                                }

                                @if (!isIframeStep) {
                                    <div class="text-center">
                                        <button
                                            (click)="submitApplication()"
                                            [disabled]="formApplication.disabled || isLoading"
                                            class="btn btn-secondary px-5">
                                            @if (isLoading) {
                                                <span class="spinner-border spinner-border-sm"></span>
                                            } @else {
                                                {{ metadata.application.main.ctaButton }}
                                            }
                                        </button>
                                    </div>
                                }
                            </form>
                        }
                    </div>
                </div>
            </div>
        </section>
    }
</app-blank>
