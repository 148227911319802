@if (!isTosAgreed && metadata.footer.cookie_modal.content) {
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <strong>{{ metadata.footer.cookie_modal.heading }}</strong>
                <p [innerHTML]="metadata.footer.cookie_modal.content"></p>
            </div>
            <button
                type="button"
                class="btn btn-sm btn-secondary"
                data-bs-dismiss="alert"
                (click)="agreeTos()">
                {{ metadata.footer.cookie_modal.children.button_text }}
            </button>
        </div>
    </div>
}
