@if (isBrowser) {
    @if (hasNavbar) {
        <app-navbar [isBlurred]="isBlurredNavbar"></app-navbar>
    }

    @if (!isLoading) {
        <ng-content></ng-content>
    }

    @if (hasFooter) {
        <app-footer></app-footer>
    }

    <app-tos></app-tos>
} @else {
    <div class="vh-100 bg-white w-100 d-flex justify-content-center align-items-center">
        <div class="spinner-border" role="status"></div>
    </div>
}
