import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";

import { EnvironmentService } from "../services/environment/environment.service";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    public constructor(
        private readonly environmentService: EnvironmentService,
    ) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {
        return from(this.handle(request, next));
    }

    private handle(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            url: this.environmentService.apiUrl + request.url + "/",
            setHeaders: {
                "Origin": this.environmentService.origin, // Should be removed from BE due to redundancy.
                "Content-Type": "application/json",
            },
        });

        return next.handle(request);
    }
}
