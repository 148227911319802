import { Component } from "@angular/core";
import { BaseFormInput } from "../base-form-input";

@Component({
    selector: "app-input-number",
    templateUrl: "./number.component.html",
    styleUrls: ["./number.component.scss"],
})
export class NumberComponent extends BaseFormInput {
    public constructor() {
        super();
    }
}
