import { Injectable } from "@angular/core";

import { FaqQuestion } from "./faq.component";
import { MetaService } from "../../services/meta/meta.service";
import { map, Observable, tap } from "rxjs";
import { RequestEndpointEnum } from "../../enums/request-endpoint.enum";
import { HttpService } from "../../services/http/http.service";
import { FaqMetadata, FaqMetadataResponse } from "./faq.interface";
import { InstanceEnum } from "../../enums/instance.enum";
import { Metadata } from "../../services/meta/meta.interface";

@Injectable({
    providedIn: "root",
})
export class FaqService {
    public constructor(
        private readonly httpService: HttpService,
        private readonly metaService: MetaService,
    ) {
    }

    public searchFaqByQuestion(query: string, questions: FaqQuestion[]): FaqQuestion[] {
        return questions.filter((question: FaqQuestion) =>
            question.question
                .normalize("NFD")
                .replace(/\p{Diacritic}/gu, "")
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1);
    }

    public get questions(): FaqQuestion[] {
        return this.metadata.faq.questions;
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public getMetadata(): Observable<FaqMetadata> {
        return this.httpService.get("/content", { section: RequestEndpointEnum.FAQ })
            .pipe(
                map((res: FaqMetadataResponse) => this.mapper(res)),
                tap((res: FaqMetadata) => this.metaService.setMetadata<FaqMetadata>(res, InstanceEnum.FAQ)),
            );
    }

    private mapper(response: FaqMetadataResponse): FaqMetadata {
        return {
            header: response.faq.header.content,
            meta: {
                title: response.faq.meta.children.title,
                description: response.faq.meta.children.desc,
            },
            questions: response.faq.questions.children.map((res) => ({ question: res.q, answer: res.a })),
            search: {
                loading: response.faq.search_msg.children.loading,
                noResults: response.faq.search_msg.children.no_results,
                placeholder: response.faq.header.children.search_placeholder,
            },
        };
    }
}
