<app-blank
    image="header/header-background-4.jpg"
    [title]="metadata.policyPrivacy.main.headline"
    [isLoading]="isLoadingInstance"
    [instanceName]="instanceName">
    @if (!isLoadingInstance) {
        <section class="py-5">
            <div class="container">
                <div [innerHTML]="metadata.policyPrivacy.main.content"></div>
            </div>
        </section>
    }
</app-blank>
