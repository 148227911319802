<app-layout [isLoading]="false">
    @if (isLoading) {
        <div class="py-8 w-100 d-flex justify-content-center align-items-center">
            <div class="spinner-border" role="status"></div>
        </div>
    } @else {
        <div class="container py-8">
            <div class="row justify-content-center">
                <div class="col-7 text-center">
                    @if (hasError) {
                        <div>
                            <h4 class="mb-3">
                                {{ metadata.applicationKickoff.jump_back_failure_greeting.content }}
                            </h4>

                            <button class="btn btn-secondary" (click)="redirectToUrl()">
                                {{ metadata.applicationKickoff.jump_back_failure_greeting.children.button_text }}
                            </button>
                        </div>
                    }

                    @if (!hasError) {
                        <div>
                            <h4 class="mb-3">
                                {{ metadata.applicationKickoff.jump_back_success_greeting.content }}
                            </h4>

                            <button class="btn btn-secondary" (click)="redirectToUrl()">
                                {{ metadata.applicationKickoff.jump_back_success_greeting.children.button_text }}
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    }
</app-layout>
