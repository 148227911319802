import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { FormField } from "../../instances/application/application.interface";
import { FormValidator } from "../../validators/form-validator.validator";

@Injectable({
    providedIn: "root",
})
export class FormService {
    public mapFormControls(form: FormGroup, fields: FormField[]): void {
        fields.map((field: FormField): void => {
            form.addControl(field.field_name || field.name, new FormControl());
        });
    }

    public mapFormControlsAndValidators(form: FormGroup, fields: FormField[]): void {
        fields.map((field: FormField): void => {
            field.name = field.name || field.field_name;
            field.type = field.type || field.field_type;

            if (field.type == "checkbox_combo") {
                return;
            }

            form.addControl(field.name, new FormControl(field?.default_value, [
                Validators.required,
            ]));

            if (field.type == "number") {
                form.get(field.name)?.setValidators([
                    Validators.required,
                    Validators.max(field.children?.maxValue),
                    Validators.min(field.children?.minValue),
                ]);
            }

            if (field.name == "dni_number") {
                form.get(field.name)?.setValidators([
                    Validators.required,
                    FormValidator.spanishId(),
                ]);
            }

            if (field.name == "birth_date") {
                form.get(field.name)?.setValidators([
                    Validators.required,
                    FormValidator.ageBetween(field.children.latestYDiff, field.children.earliestYDiff),
                ]);
            }

            if (field.validation_regexp) {
                form.get(field.name)?.addValidators(Validators.pattern(new RegExp(field.validation_regexp)));
            }

            if (field.type == "checkbox") {
                form.get(field.name)?.removeValidators(Validators.required);
                form.get(field.name)?.addValidators(Validators.requiredTrue);
                form.get(field.name)?.patchValue(false);
            }

            if (field.checked == "true" || (field.children && field.children.checked == "true")) {
                form.get(field.name)?.patchValue(true);
            }

            if (field.required == "false" || (field.children && field.children.required == "false")) {
                form.get(field.name)?.removeValidators(Validators.required);
                form.get(field.name)?.removeValidators(Validators.requiredTrue);
            }
        });
    }

    public mapFieldOptions(options: string[]): { key: string; label: string; }[] | null {
        if (!options && !this.isIterable(options) && !Array.isArray(options)) {
            return null;
        }

        const mappedOptions: { key: string; label: string; }[] = [];

        for (const value of Object.values(options)) {
            mappedOptions.push({
                key: value,
                label: value,
            });
        }

        return mappedOptions;
    }

    public mapApplicationFormValues(formApplicationValue: { [key: string]: string | number | boolean; }, formTimestampValue: { [key: string]: number; }): object {
        const mappedValues: { [key: string]: { value: string | number | null; t1: number; } } = {};
        let key: keyof typeof formApplicationValue;

        for (key in formApplicationValue) {
            mappedValues[key] = {
                t1: formTimestampValue[key],
                value: this.mapPayloadValue(key, formApplicationValue[key]),
            };
        }

        return mappedValues;
    }

    public mapContactFormValues(formApplicationValue: { [key: string]: string | number | boolean; }, formTimestampValue: { [key: string]: number; }): object {
        const mappedValues: { [key: string]: { value: string | number | null; t: number; } } = {};
        let key: keyof typeof formApplicationValue;

        for (key in formApplicationValue) {
            mappedValues[key] = {
                t: formTimestampValue[key],
                value: this.mapPayloadValue(key, formApplicationValue[key]),
            };
        }

        return mappedValues;
    }

    public mapPayloadValue(key: string, value: string | number | boolean): string | number | null {
        if (typeof value == "boolean") {
            return String(value);
        } else if (key == "select") {
            return null;
        } else {
            return value;
        }
    }

    private isIterable(input: any): boolean {
        if (input === null || input === undefined) {
            return false;
        }

        return typeof input[Symbol.iterator] === "function";
    }

    public isFormControlInvalid(form: FormGroup, name: string): boolean {
        return form.controls[name]?.invalid && (form.controls[name].dirty || form.controls[name].touched);
    }

    public isFormControlValid(form: FormGroup, name: string): boolean {
        return form.controls[name]?.valid && (form.controls[name].dirty || form.controls[name].touched);
    }

    public removeAllFormControls(form: FormGroup): void {
        Object.entries(form.controls).map(([key, value]): void => {
            form.removeControl(key);
        });
    }
}
