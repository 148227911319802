import { Injectable } from "@angular/core";
import { map, Observable, tap } from "rxjs";

import { HttpService } from "../http/http.service";
import { StorageEnum } from "../../enums/storage.enum";
import { HeadService } from "../head/head.service";
import { InstanceEnum } from "../../enums/instance.enum";
import { LoaderService } from "../loader/loader.service";
import { StorageService } from "../storage/storage.service";
import { NotFoundMetadata } from "../../instances/not-found/not-found.interface";
import { RequestEndpointEnum } from "../../enums/request-endpoint.enum";
import { Footer, GeneralMetadata, GeneralMetadataResponse, Header, Metadata } from "./meta.interface";

@Injectable({
    providedIn: "root",
})
export class MetaService {
    public constructor(
        private readonly httpService: HttpService,
        private readonly headService: HeadService,
        private readonly loaderService: LoaderService,
        private readonly storageService: StorageService,
    ) {
    }

    public getMetadata(): Observable<GeneralMetadata> | null {
        return this.httpService.get("/content", { section: RequestEndpointEnum.GENERAL }).pipe(
            map((res: GeneralMetadataResponse) => {
                this.mergeMetadata({ notFound: this.notFoundMapper(res) });
                return this.mapper(res);
            }),
            tap((metadata: GeneralMetadata) => {
                this.mergeMetadata<GeneralMetadata>(metadata);
            }),
        );
    }

    private mapper(response: GeneralMetadataResponse): GeneralMetadata {
        return {
            applicationKickoff: response.application_kickoff,
            footer: response.mainfooter,
            header: response.mainheader,
            theme: response.mainheader.theme.children.theme_name.replace("_", "."),
            landing: {
                meta: {
                    title: response.home.meta.children.title,
                    description: response.home.meta.children.desc,
                },
                statistics: {
                    items: response.home.statistics.children,
                },
                testimonials: {
                    heading: response.home.testimonials.heading,
                    items: response.home.testimonials.children,
                },
                advantages: {
                    heading: response.home.advantages.heading,
                    ctaButton: response.home.advantages.children.cta_button,
                    items: response.home.advantages.children.items,
                },
                howItWorks: {
                    content: response.home.how_it_works.content,
                    heading: response.home.how_it_works.heading,
                    items: response.home.how_it_works.children.steps,
                    ctaAfter: response.home.how_it_works.children.cta_after,
                    ctaButton: response.home.how_it_works.children.cta_button,
                },
                howItWorksWizard: {
                    heading: response.home.how_it_works_wizard.heading,
                    content: response.home.how_it_works_wizard.content,
                    ctaButton: response.home.how_it_works_wizard.children.cta_button,
                    items: response.home.how_it_works_wizard.children.steps,
                },
                resumeStartedApplication: {
                    heading: response.home.resume_started_application.heading,
                    continue: response.home.resume_started_application.children.continue,
                    startNew: response.home.resume_started_application.children.start_new,
                },
                howMuchDoYouNeed: {
                    heading: response.home.how_much_do_you_need.heading,
                    options: response.home.how_much_do_you_need.children.options,
                    ctaButton: response.home.how_much_do_you_need.children.cta_button,
                },
            },
            meta: {
                googleTagId: response.meta?.google_meta?.children?.GA4_ID,
                manifest: response.meta.manifest.content,
                htmlLang: response.meta.main_meta.children.html_lang,
                logoDarkUrl: response.meta.main_meta.children.logo_dark_url,
                logoLightUrl: response.meta.main_meta.children.logo_light_url,
                selectAllToggle: response.meta.select_all_toggle.content,
            },
            routes: response.mainpaths.paths.children,
        };
    }

    private notFoundMapper(response: GeneralMetadataResponse): NotFoundMetadata {
        return {
            content: response.home["404"].content,
            heading: response.home["404"].heading,
            ctaButton: response.home["404"].children.cta_button,
        };
    }

    public setMetadata<T>(metadata: T, instanceName: RequestEndpointEnum | InstanceEnum) {
        this.mergeMetadata({ [instanceName]: metadata });
        this.setTitleAndDescription(instanceName);
        this.loaderService.setInstanceLoader({ name: instanceName, isLoading: false });
    }

    private mergeMetadata<T>(metadata: T): void {
        this.metadata = { ...this.metadata, ...metadata };
    }

    public setTitleAndDescription(instanceName: string | null): void {
        // TODO: Add typing/or refactor
        if (!instanceName) {
            this.headService.setPageTitle(this.defaultTitle);
            this.headService.setPageDescription(this.defaultDescription);
        } else {
            this.headService.setPageTitle((this.metadata as any)[instanceName]?.meta?.title || this.defaultTitle);
            this.headService.setPageDescription((this.metadata as any)[instanceName]?.meta?.description || this.defaultDescription);
        }
    }

    public get defaultTitle(): string {
        return this.metadata.landing.meta.title;
    }

    public get defaultDescription(): string {
        return this.metadata.landing.meta.description;
    }

    public get footer(): Footer {
        return this.metadata.footer;
    }

    public get header(): Header {
        return this.metadata.header;
    }

    public get metadata(): Metadata {
        return JSON.parse(this.storageService.getItem(StorageEnum.METADATA) || "{}");
    }

    public set metadata(metadata: Metadata) {
        this.storageService.setItem(StorageEnum.METADATA, JSON.stringify(metadata));
    }

    public get getGtmId(): string {
        return this.metadata.meta.googleTagId;
    }
}
