import { SpainUtil } from "../utils/spain.util";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export class FormValidator {
    public static spanishId(): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control.value) {
                return null;
            }

            return !SpainUtil.validateSpanishId(control.value) ? { spanishId: true } : null;
        };
    }

    public static ageBetween(minAge: number, maxAge: number): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control.value) {
                return null;
            }

            const today = new Date();
            const dob = new Date(control.value);

            // Added +1 to day because you must be 18 years and 1 day old in Spain to be eligible.
            const dobCalculatedMin = new Date(dob.getFullYear() + minAge, dob.getMonth(), dob.getDate() + 1);
            const dobCalculatedMax = new Date(dob.getFullYear() + maxAge + 1, dob.getMonth(), dob.getDate());

            return dobCalculatedMin >= today || dobCalculatedMax < today ? { underAge: true } : null;
        };
    }

    public static dayExists(): ValidationErrors {
        return (formGroup: FormGroup) => {
            const day = formGroup.get("day");
            const month = formGroup.get("month");
            const year = formGroup.get("year");

            if (!day?.value || !month?.value || !year?.value) {
                return null;
            }

            if ((new Date(year?.value, Number(month?.value) - 1, day?.value)).getMonth() == Number(month?.value)) {
                day.setErrors({ dayExists: true });
                return {
                    dayExists: true,
                };
            } else {
                return null;
            }
        };
    }

    public static requiredIfValidator(predicate: Function): ValidationErrors {
        return ((formControl: FormControl) => {
            if (!formControl.parent) {
                return null;
            }

            if (predicate()) {
                return Validators.required(formControl);
            }

            return null;
        });
    }

    public static isTypeNumber(): ValidationErrors | null {
        return ((control: AbstractControl) => {
            if (!control.value) {
                return null;
            }

            const NUMBER_REGEX = /^[0-9]*$/u;
            return !control.value.match(NUMBER_REGEX) ? { typeNumber: true } : null;
        });
    }
}
