import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Observable, Subscription, timer } from "rxjs";
import { Component, HostListener, Input, OnDestroy, OnInit } from "@angular/core";

import { ServiceEnum } from "../../enums/service.enum";
import { UnnaxService } from "../../services/unnax/unnax.service";

@Component({
    selector: "app-iframe",
    templateUrl: "./iframe.component.html",
    styleUrls: ["./iframe.component.scss"],
})
export class IframeComponent implements OnInit, OnDestroy {
    private _url!: string;
    private _initTimer!: Subscription;
    private _name!: string;
    private _service!: string;
    private _hasSkipStep: boolean = false;
    private _loadTimeout!: number;
    private _bankLoadTimeout!: number;
    private _failedLoginMaxTries!: number;
    private _loaderMessage!: string;
    private _delayBeforeNextStep: number = 2000;
    private _skipStepText!: string;
    private _skipStepDelayBeforeDisplay!: number;

    public constructor(
        private readonly sanitizer: DomSanitizer,
        private readonly unnaxService: UnnaxService,
    ) {
    }

    public ngOnInit(): void {
        this.initTimer = this.timer(this.delayBeforeNextStep).subscribe((time: number) => {
            this.hasSkipStep = true;
        });
    }

    public ngOnDestroy(): void {
        this.initTimer.unsubscribe();
    }

    private timer(delay: number): Observable<number> {
        return timer(delay);
    }

    @HostListener("window:message", ["$event.data"])
    public iframeOperations(message: string): void {
        if (message && message?.includes("unnax:")) {
            this.unnaxService.operations(message);
        }
    }

    public sanitizeUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public get initTimer(): Subscription {
        return this._initTimer;
    }

    public set initTimer(initTimer: Subscription) {
        this._initTimer = initTimer;
    }

    public get hasSkipStep(): boolean {
        return this._hasSkipStep;
    }

    public set hasSkipStep(hasSkipStep: boolean) {
        this._hasSkipStep = hasSkipStep;
    }

    public get ServiceEnum(): typeof ServiceEnum {
        return ServiceEnum;
    }

    public get url(): string {
        return this._url;
    }

    @Input()
    public set url(url: string) {
        this._url = url;
    }

    public get name(): string {
        return this._name;
    }

    @Input()
    public set name(name: string) {
        this._name = name;
    }

    public get service(): string {
        return this._service;
    }

    @Input()
    public set service(service: string) {
        this._service = service;
    }

    public get loadTimeout(): number {
        return this._loadTimeout;
    }

    @Input()
    public set loadTimeout(loadTimeout: number) {
        this._loadTimeout = loadTimeout;
    }

    public get bankLoadTimeout(): number {
        return this._bankLoadTimeout;
    }

    @Input()
    public set bankLoadTimeout(bankLoadTimeout: number) {
        this._bankLoadTimeout = bankLoadTimeout;
    }

    public get failedLoginMaxTries(): number {
        return this._failedLoginMaxTries;
    }

    @Input()
    public set failedLoginMaxTries(failedLoginMaxTries: number) {
        this._failedLoginMaxTries = failedLoginMaxTries;
    }

    public get loaderMessage(): string {
        return this._loaderMessage;
    }

    @Input()
    public set loaderMessage(loaderMessage: string) {
        this._loaderMessage = loaderMessage;
    }

    public get delayBeforeNextStep(): number {
        return this._delayBeforeNextStep;
    }

    @Input()
    public set delayBeforeNextStep(delayBeforeNextStep: number) {
        this._delayBeforeNextStep = delayBeforeNextStep * 1000;
    }

    public get skipStepText(): string {
        return this._skipStepText;
    }

    @Input()
    public set skipStepText(skipStepText: string) {
        this._skipStepText = skipStepText;
    }

    public get skipStepDelayBeforeDisplay(): number {
        return this._skipStepDelayBeforeDisplay;
    }

    @Input()
    public set skipStepDelayBeforeDisplay(skipStepDelayBeforeDisplay: number) {
        this._skipStepDelayBeforeDisplay = skipStepDelayBeforeDisplay;
    }
}
