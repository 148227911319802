import { Observable, tap } from "rxjs";
import { SsrCookieService } from "ngx-cookie-service-ssr";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

import { DateUtil } from "../../utils/date.util";
import { CookieEnum } from "../../enums/cookie.enum";
import { HttpService } from "../http/http.service";
import { StorageEnum } from "../../enums/storage.enum";
import { StorageService } from "../storage/storage.service";
import { PageDataService } from "../page-data/page-data.service";
import { isPlatformServer } from "@angular/common";

export interface VisitNewResponse {
    vid: string;
    domain: string;
    maxAge: number;
}

@Injectable({
    providedIn: "root",
})
export class SessionService {
    public constructor(
        private readonly httpService: HttpService,
        private readonly cookieService: SsrCookieService,
        private readonly storageService: StorageService,
        private readonly pageDataService: PageDataService,
        @Inject(PLATFORM_ID) private readonly platformId: Object,
    ) {
    }

    public registerNewSession(): Observable<VisitNewResponse> | null {
        if (isPlatformServer(this.platformId)) {
            return null;
        }

        if (!this.applicationProgress) {
            this.applicationProgress = StorageEnum.APPLICATION_PROGRESS_NOT_STARTED_VALUE;
        }

        return this.httpService.post("/visit/new", {
                srch: this.pageDataService.getUrlQueryParams(),
                location: this.pageDataService.getUrlPath(),
                ref: this.pageDataService.getUrlQueryParams("utm_ref"),
                new_visit: this.isNewVisit,
                vc: this.sessionId,
            })
            .pipe(
                tap((res: VisitNewResponse) => {
                    this.storageService.setItem(StorageEnum.DOMAIN_NAME, res.domain);
                    this.storageService.setItem(StorageEnum.PAGE_LOADED_AT, DateUtil.getCurrentUnixTimestamp().toString());

                    if (this.isNewVisit) {
                        this.cookieService.set(CookieEnum.SESSION_ID, res.vid, (res.maxAge / 60 / 60 / 24));
                    }
                }),
            );
    }

    public get sessionId(): string {
        return this.cookieService.get(CookieEnum.SESSION_ID);
    }

    private get isNewVisit(): boolean {
        return !this.sessionId;
    }

    private get applicationProgress(): string | null {
        return this.storageService.getItem(StorageEnum.APPLICATION_PROGRESS);
    }

    private set applicationProgress(applicationProgress: StorageEnum.APPLICATION_PROGRESS_NOT_STARTED_VALUE | StorageEnum.APPLICATION_PROGRESS_STARTED_VALUE) {
        this.storageService.setItem(StorageEnum.APPLICATION_PROGRESS, applicationProgress);
    }
}
