<div
    (click)="setIsOpen(true)"
    class="form-floating"
    [formGroup]="formGroup"
    ngbDropdown>
    <input
        [id]="name"
        [type]="type"
        class="form-control"
        [class.is-valid]="isFormControlValid"
        [class.is-invalid]="isFormControlInvalid"
        [placeholder]="placeholder"
        [formControlName]="name"
        ngbDropdownToggle
        [readOnly]="true"
    >

    <label [for]="name">
        <span [innerHTML]="label"></span>
    </label>

    @if (isLoading) {
        <div class="form-control-spinner">
            <div class="spinner-border spinner-border-sm"></div>
        </div>
    }

    <div ngbDropdownMenu class="dropdown-select">
        <div class="p-2">
            <input
                type="text"
                class="form-control form-control-sm"
                [placeholder]="label"
                (input)="onChangeDropdownSearchInput($event)"
            />
        </div>

        <div class="dropdown-select-inner">
            @if (placeholder) {
                <button ngbDropdownItem>
                    {{ placeholder }}
                </button>
            }

            @for (option of optionsReplication; track $index) {
                <button
                    ngbDropdownItem
                    (click)="onSelect(option)">
                    {{ option }}
                </button>
            } @empty {
                <div class="dropdown-select-not-found">
                    {{ metadata.application.dropdown.notFound }}
                </div>
            }
        </div>
    </div>
</div>
