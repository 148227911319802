<app-blank
    [hasFooter]="false"
    [hasNavbar]="false"
    [isLoading]="isLoadingInstance">
    @if (!isLoadingInstance) {
        <div class="container">
            <div class="d-flex py-6 text-center vh-100 d-flex justify-content-center">
                <div class="d-flex align-items-center h-100">
                    <div>
                        <h1>
                            {{ metadata.notFound.heading }}
                        </h1>
                        <p>
                            {{ metadata.notFound.content }}
                        </p>
                        <button class="btn btn-secondary mt-4" (click)="redirectToLandingPage()">
                            {{ metadata.notFound.ctaButton }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }
</app-blank>
