import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "app-form-error",
    templateUrl: "./error.component.html",
    styleUrls: ["./error.component.scss"],
})
export class FormErrorComponent {
    private _name!: string;
    private _formGroup!: FormGroup;

    public get name(): string {
        return this._name;
    }

    @Input()
    public set name(name: string) {
        this._name = name;
    }

    public get formGroup(): FormGroup {
        return this._formGroup;
    }

    @Input()
    public set formGroup(formGroup: FormGroup) {
        this._formGroup = formGroup;
    }
}
