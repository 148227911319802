import { Router } from "@angular/router";
import { delayWhen, finalize, interval, of, timer } from "rxjs";
import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";

import { begin } from "../utils/observer.util";

@Directive({
    standalone: true,
    selector: "[buttonLink]",
})
export class ButtonLinkDirective implements OnInit {
    @Input({ required: true })
    buttonLink!: string;

    @Input()
    buttonLinkDelay: number = 0;

    @Input()
    buttonLinkQueryParams!: any;

    @Input()
    isButtonLinkLoading: boolean = false;

    @Output()
    buttonLinkCallback: EventEmitter<any> = new EventEmitter();

    public constructor(
        private readonly router: Router,
        private readonly element: ElementRef,
    ) {
    }

    public ngOnInit(): void {
        if (this.isButtonLinkLoading) {
            this.element.nativeElement.innerHTML = "<span class=\"spinner-border spinner-border-sm\"></span>";
        }
    }

    @HostListener("click", ["$event.target"])
    public onClick(element: any): void {
        const t0: number = performance.now();

        timer(0)
            .pipe(
                begin(() => {
                    element.innerHTML = "<span class=\"spinner-border spinner-border-sm\"></span>";
                    element.disabled = true;
                }),
                delayWhen(() => {
                    const t1: number = performance.now();
                    const loadingTimeMs: number = t1 - t0;
                    return loadingTimeMs < this.buttonLinkDelay ? interval(this.buttonLinkDelay - loadingTimeMs) : of(undefined);
                }),
                finalize(async () => {
                    if (this.buttonLink) {
                        await this.router.navigate([this.buttonLink], {
                            queryParams: this.buttonLinkQueryParams,
                        });
                    } else if (this.buttonLinkCallback) {
                        this.buttonLinkCallback.emit();
                    }
                }),
            )
            .subscribe();
    }
}
