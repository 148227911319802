import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserModule, provideClientHydration, Title } from "@angular/platform-browser";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from "@angular/common/http";

import { HowModule } from "./instances/how/how.module";
import { FaqModule } from "./instances/faq/faq.module";
import { AboutModule } from "./instances/about/about.module";
import { MetaService } from "./services/meta/meta.service";
import { AppComponent } from "./app.component";
import { LandingModule } from "./instances/landing/landing.module";
import { ContactModule } from "./instances/contact/contact.module";
import { NotFoundModule } from "./instances/not-found/not-found.module";
import { RedirectModule } from "./instances/redirect/redirect.module";
import { ShortUrlModule } from "./instances/short-url/short-url.module";
import { SessionService } from "./services/session/session.service";
import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { HeaderInterceptor } from "./interceptors/header.interceptor";
import { ApplicationModule } from "./instances/application/application.module";
import { PolicyTermsModule } from "./instances/policy/terms/policy-terms.module";
import { UnsubscribeModule } from "./instances/unsubscribe/unsubscribe.module";
import { PolicyPrivacyModule } from "./instances/policy/privacy/policy-privacy.module";
import { PolicyCookiesModule } from "./instances/policy/cookies/policy-cookies.module";
import { PageDataService } from "./services/page-data/page-data.service";

@NgModule({
    imports: [
        BrowserModule,
        NgxMaskDirective,
        NgxMaskPipe,
        LandingModule,
        AboutModule,
        NotFoundModule,
        HowModule,
        FaqModule,
        ContactModule,
        ApplicationModule,
        RedirectModule,
        PolicyTermsModule,
        PolicyPrivacyModule,
        PolicyCookiesModule,
        UnsubscribeModule,
        ShortUrlModule,
        AppRoutingModule,
        ComponentsModule,
        HttpClientModule,
        NgbModule,
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        provideHttpClient(withFetch()),
        provideClientHydration(),
        provideNgxMask({
            validation: false,
        }),
        Title,
        {
            multi: true,
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
        },
        {
            deps: [MetaService],
            multi: true,
            provide: APP_INITIALIZER,
            useFactory: (metaService: MetaService) => {
                return () => metaService.getMetadata();
            },
        },
        {
            deps: [SessionService],
            multi: true,
            provide: APP_INITIALIZER,
            useFactory: (sessionService: SessionService) => {
                return () => sessionService.registerNewSession();
            },
        },
        {
            deps: [PageDataService, MetaService],
            multi: true,
            provide: APP_INITIALIZER,
            useFactory: (pageDataService: PageDataService, metaService: MetaService) => {
                return () => pageDataService.loadStyleTheme(metaService.metadata.theme);
            },
        },
    ],
    bootstrap: [
        AppComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
})
export class AppModule {
}
