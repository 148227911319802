<div class="form-group mb-3" [formGroup]="formGroup">
    @if (headline) {
        <h4 [innerHTML]="headline"></h4>
    }

    @if (paragraph) {
        <div class="mb-3" [innerHTML]="paragraph"></div>
    }

    @if (helperText && type != "checkbox_combo") {
        <app-form-helper
            [helperText]="helperText"
        />
    }

    @if (type == "text") {
        <app-input-text
            [name]="name"
            [label]="label"
            [placeholder]="placeholder"
            [formGroup]="formGroup"
            [isLoading]="isAddressQueryLoading">
        </app-input-text>
    }

    @if (type == "password_combo") {
        <app-input-password
            [name]="name"
            [label]="label"
            [placeholder]="placeholder"
            [formGroup]="formGroup">
        </app-input-password>
    }

    @if (type == "datepick_combo") {
        <app-input-datetime
            [name]="name"
            [label]="label"
            [options]="options"
            [placeholder]="placeholder"
            [formGroup]="formGroup"
            [data]="data"
            (hasValidator)="hasValidator$($event)">
        </app-input-datetime>
    }

    @if (type == "button_options") {
        <app-input-radio-group
            [name]="name"
            [label]="label"
            [options]="options"
            [formGroup]="formGroup">
        </app-input-radio-group>
    }

    @if (type == "checkbox_combo") {
        <app-input-checkbox-combo
            [name]="name"
            [label]="label"
            [children]="children"
            [formGroup]="formGroup">
        </app-input-checkbox-combo>
    }

    @if (type == "checkbox") {
        <app-input-checkbox
            [name]="name"
            [label]="label"
            [formGroup]="formGroup">
        </app-input-checkbox>
    }

    @if (type == "phone") {
        <app-input-phone-number
            [name]="name"
            [label]="label"
            [prepend]="prepend"
            [children]="children"
            [placeholder]="placeholder"
            [formGroup]="formGroup">
        </app-input-phone-number>
    }

<!--    @if (type == "options") {-->
<!--        <app-input-select-->
<!--            [name]="name"-->
<!--            [label]="label"-->
<!--            [options]="options"-->
<!--            [placeholder]="placeholder"-->
<!--            [formGroup]="formGroup"-->
<!--            [isLoading]="isAddressQueryLoading">-->
<!--        </app-input-select>-->
<!--    }-->

    @if (type == "options") {
        <app-select-dropdown
            [name]="name"
            [label]="label"
            [options]="options"
            [placeholder]="placeholder"
            [formGroup]="formGroup"
            [isLoading]="isAddressQueryLoading">
        </app-select-dropdown>
    }

    @if (type == "textarea") {
        <app-input-textarea
            [name]="name"
            [label]="label"
            [placeholder]="placeholder"
            [formGroup]="formGroup"
            [isLoading]="isAddressQueryLoading">
        </app-input-textarea>
    }

    @if (type == "number") {
        <app-input-number
            [name]="name"
            [label]="label"
            [children]="children"
            [placeholder]="placeholder"
            [formGroup]="formGroup">
        </app-input-number>
    }

    @if (hasValidator && type != "checkbox_combo" && errorMessage && isFormControlInvalid) {
        <app-form-error
            [name]="name"
            [formGroup]="formGroup">
            {{ errorMessage }}
        </app-form-error>
    }
</div>
