import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FaqComponent } from "./faq.component";
import { ComponentsModule } from "../../components/components.module";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        FaqComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        NgbAccordionModule,
    ],
})
export class FaqModule {
}
