export enum InstanceEnum {
    FAQ = "faq",
    HOW = "howItWorks",
    ABOUT = "about",
    LANDING = "landing",
    CONTACT = "contact",
    APPLICATION = "application",
    UNSUBSCRIBE = "unsubscribe",
    POLICY_TERMS = "policyTerms",
    POLICY_PRIVACY = "policyPrivacy",
    POLICY_COOKIES = "policyCookies",
}
