import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
    providedIn: "root",
})
export class ModalService {
    public constructor(
        private readonly ngbModalService: NgbModal,
    ) {
    }

    public open(component: any, data?: any): NgbModalRef {
        const modalRef: NgbModalRef = this.ngbModalService.open(component, {
            centered: true,
        });

        modalRef.componentInstance.data = data;

        return modalRef;
    }

    public get hasOpenModals(): boolean {
        return this.ngbModalService.hasOpenModals();
    }
}
