import { Component, OnInit } from "@angular/core";

import { Metadata } from "../../services/meta/meta.interface";
import { MetaService } from "../../services/meta/meta.service";
import { ShortUrlService } from "./short-url.service";

@Component({
    selector: "app-short-url",
    templateUrl: "./short-url.component.html",
    styleUrls: ["./short-url.component.scss"],
})
export class ShortUrlComponent implements OnInit {
    public constructor(
        private readonly metaService: MetaService,
        private readonly shortUrlService: ShortUrlService,
    ) {
    }

    public ngOnInit(): void {
        this.metaService.setTitleAndDescription(null);
    }

    public get isLoading(): boolean {
        return this.shortUrlService.isLoading;
    }

    public get hasError(): boolean {
        return this.shortUrlService.hasError;
    }

    public get url(): string {
        return this.shortUrlService.url;
    }

    public get metadata(): Metadata {
        return this.metaService.metadata;
    }

    public async redirectToUrl(): Promise<void> {
        return await this.shortUrlService.redirectToUrl();
    }
}
