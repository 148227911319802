import { Injectable } from "@angular/core";
import { HttpService } from "../../services/http/http.service";
import { MetaService } from "../../services/meta/meta.service";
import { map, Observable, tap } from "rxjs";
import { RequestEndpointEnum } from "../../enums/request-endpoint.enum";
import { UnsubscribeMetadata, UnsubscribeMetadataResponse } from "./unsubscribe.interface";

@Injectable({
    providedIn: "root",
})
export class UnsubscribeService {
    public constructor(
        private readonly httpService: HttpService,
        private readonly metaService: MetaService,
    ) {
    }

    public getMetadata(): Observable<UnsubscribeMetadata> {
        return this.httpService.get("/content", { section: RequestEndpointEnum.UNSUBSCRIBE })
            .pipe(
                map((res: UnsubscribeMetadataResponse) => this.mapper(res)),
                tap((res: UnsubscribeMetadata) => this.metaService.setMetadata<UnsubscribeMetadata>(res, RequestEndpointEnum.UNSUBSCRIBE)),
            );
    }

    private mapper(response: UnsubscribeMetadataResponse): UnsubscribeMetadata {
        return {
            blankArrivalForm: {
                button: response.unsubscribe.blank_arrival_form.children.button_text,
                content: response.unsubscribe.blank_arrival_form.content,
                field: {
                    label: response.unsubscribe.blank_arrival_form.children.label,
                    name: response.unsubscribe.blank_arrival_form.children.name,
                    validationError: response.unsubscribe.blank_arrival_form.children.validation_error,
                    validationRegex: response.unsubscribe.blank_arrival_form.children.validation_regexp,
                },
            },
            checkboxes: {
                unsubscribeEmail: response.unsubscribe.checkboxes.children.unsubscribe_email,
                unsubscribeSms: response.unsubscribe.checkboxes.children.unsubscribe_sms,
            },
            header: {
                content: response.unsubscribe.hero_text.content,
            },
            main: {
                button: response.unsubscribe.button_text.content,
                content: response.unsubscribe.main.content,
                error: response.unsubscribe.error_message.content,
                heading: response.unsubscribe.main.heading,
            },
            meta: {
                title: response.unsubscribe.meta.children.title,
                description: response.unsubscribe.meta.children.desc,
            },
            thankYouFeedbackLower: {
                children: response.unsubscribe.thankyou_feedback_lower.children, // TODO
                content: response.unsubscribe.thankyou_feedback_lower.content,
            },
            thankYouFeedbackUpper: {
                content: response.unsubscribe.thankyou_feedback_upper.content,
                heading: response.unsubscribe.thankyou_feedback_upper.heading,
            },
            thankYouGoodbye: {
                button: response.unsubscribe.thankyou_goodbye.children.home_button_text,
                content: response.unsubscribe.thankyou_goodbye.content,
                heading: response.unsubscribe.thankyou_goodbye.heading,
            },
        };
    }
}
