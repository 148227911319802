import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";

import { MetaService } from "./services/meta/meta.service";
import { PageDataService } from "./services/page-data/page-data.service";
import { ApplicationService } from "./instances/application/application.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    public constructor(
        private readonly metaService: MetaService,
        private readonly pageDataService: PageDataService,
        private readonly applicationService: ApplicationService,
        @Inject(PLATFORM_ID) private readonly platformId: Object,
    ) {
        if (!this.pageDataService.getUrlQueryParams().includes("utm_seq")) {
            this.applicationService.isApplicationReturned = null;
        } else {
            this.applicationService.returnApplication();
        }
    }

    public get isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    async ngOnInit(): Promise<void> {
        if (this.isBrowser) {
            const tagId = this.metaService.metadata.meta.googleTagId;
            const head = document.getElementsByTagName("head")[0];

            const gtagLink = document.createElement("script");
            gtagLink.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;
            gtagLink.async = true;

            const gtagScript = document.createElement("script");
            gtagScript.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${tagId}');`;

            head.insertBefore(gtagScript, head.firstChild);
            head.insertBefore(gtagLink, head.firstChild);
        }
    }
}
