import { Component, Input } from "@angular/core";
import { BaseFormInput } from "../base-form-input";

@Component({
    selector: "app-input-text",
    templateUrl: "./text.component.html",
    styleUrls: ["./text.component.scss"],
})
export class TextComponent extends BaseFormInput {
    public constructor() {
        super();
    }
}
